import { useEffect, useState } from "react";
import moment from "moment";

import useScheduleStore from "../../stores/scheduleStore";

export default function Location({ location }) {
  const fetchLocation = useScheduleStore((state) => state.fetchLocation);

  return (
    <tr>
      <td>{location.name}</td>
      <td>
        <button
          className="btn"
          onClick={() => {
            useScheduleStore.setState({
              showLocationForm: true,
              locationToEdit: location,
            });
          }}>
          <i className="fa-solid fa-pencil"></i>
        </button>
      </td>
    </tr>
  );
}
