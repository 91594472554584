import useScheduleStore from "../../stores/scheduleStore";

export default function AssignmentCard({ assignment }) {
  const confirmScheduleEquipment = useScheduleStore((state) => state.confirmScheduleEquipment);

  return (
    <div className={`assignment-card  ${assignment.confirmed ? "confirmed" : "unconfirmed"}`}>
      <div className="assignment-card-info">
        <div className="crew-member-name">
          <i className={`fa-solid fa-circle-check confirm-check  ${assignment.confirmed ? "confirmed" : "unconfirmed"}`} onClick={() => confirmScheduleEquipment(assignment.id)}></i>
          {assignment.crew_member_full_name}
        </div>
        {assignment.day_shift && !!assignment.night_shift_crew_member_id &&
          <div className="crew-member-name">
            {assignment.night_shift_crew_member_full_name} <span style={{ fontSize: "18px"}}>{assignment.day_shift_end_time}</span>
          </div>
        }
        <div className="equipment-name">{assignment.equipment_name}</div>
      </div>
    </div>
  );
}
