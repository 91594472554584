import { Formik, Form, Field, ErrorMessage } from "formik";

import useCustomerMapPinStore from "../stores/customerMapPinStore";

export default function MapPinForm() {
  const pinCoords = useCustomerMapPinStore((state) => state.newPinCoords);
  const createPin = useCustomerMapPinStore((state) => state.createPin);

  const renderForm = () => {
    return (
      <div className="pin-form">
        <Formik
          enableReinitialize={true}
          initialValues={{ lat: pinCoords.lat, long: pinCoords.lng, note: "", icon_value: "red-dot" }}
          onSubmit={async (values) => {
            const updated = await createPin(values);
          }}>
          {({ isSubmitting }) => (
            <Form>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <label className="formik-label" htmlFor="icon_value">
                      Color
                    </label>
                    <Field name="icon_value" label="Color" as="select">
                      <option value="red-dot">Red</option>
                      <option value="green-dot">Green</option>
                      <option value="blue-dot">Blue</option>
                      <option value="purple-dot">Purple</option>
                      <option value="pink-dot">Pink</option>
                    </Field>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label className="formik-label" htmlFor="note">
                      note
                    </label>
                    <Field name="note" label="note" type="text" />
                    <ErrorMessage name="note" component="div" className="formik-error" />
                  </div>
                </div>
              </div>
              <div className="action-stuck-bottom">
                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <div>{renderForm()}</div>;
}
