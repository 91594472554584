import ReactModal from "react-modal";

import useUIStore from "../stores/uiStore";
import useAppStore from "../stores/appStore";
import useEquipmentStore from "../stores/equipmentStore";

import { getDeviceId } from "../lib/storage";
import Accordion from "../components/Accordion";
import DumpLocationList from "../components/DumpLocationList";
import WeatherInfo from "./WeatherInfo";

export default function ProfileModal() {
  const showProfileModal = useUIStore((state) => state.showProfileModal);
  const clientName = useAppStore((state) => state.clientName);
  const customerName = useAppStore((state) => state.customerName);
  const farmName = useAppStore((state) => state.farmName);
  const fieldName = useAppStore((state) => state.fieldName);
  const acreage = useAppStore((state) => state.acreage);
  const equipment = useEquipmentStore((state) => state.equipment);

  return (
    <>
      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showProfileModal}
        // onAfterOpen={
        //   () => fetchCustomerFields()
        //   /* Function that will be run after the modal has opened. */
        // }
        contentLabel="My Profile"
        style={{
          overlay: {
            zIndex: 99999998,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        {/* <h4 className="text-center">My Profile</h4>
        <button onClick={() => useUIStore.setState({ showProfileModal: false })}>Close <i className="fa-solid fa-times"></i></button>
        <div></div> */}
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">My Profile</h4>
            <button onClick={() => useUIStore.setState({ showProfileModal: false })}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <div className="modal-content">
            <div className="container">
              <div className="row align-stretch">
                <div className="col-4">
                  <div className="profile-card">
                    <div className="profile-small-label">Client</div>
                    <div className="profile-info">{clientName}</div>

                    <div className="profile-small-label">Farm</div>
                    <div className="profile-info">{farmName}</div>

                    <div className="profile-small-label">Assigned Field</div>
                    <div className="profile-info">{fieldName}</div>

                    <div className="profile-small-label">Acreage</div>
                    <div className="profile-info">{acreage}</div>
                  </div>

                  <div className="profile-card">
                    <div className="profile-small-label">Customer</div>
                    <div className="profile-info">{customerName}</div>

                    <div
                      className="profile-small-label"
                      onClick={() => useUIStore.setState({ showProfileModal: true, showDeviceNameModal: true })}>
                      Equipment
                    </div>
                    <div className="profile-info">{equipment?.name}</div>
                  </div>

                  <div className="profile-card">
                    <div className="profile-small-label">iPad</div>
                    <div className="profile-info">{getDeviceId()}</div>

                    <div className="profile-small-label" onClick={() => window.placeMe("sdfasdf", "asdfasdf", 1, 25, 1)}>
                      App Version
                    </div>
                    <div className="profile-info">4242023</div>
                  </div>

                  <div className="profile-card">
                    <div className="profile-small-label">Support Phone Number</div>
                    <div className="profile-info">855-747-3240</div>

                    <div className="profile-small-label">Support Email Address</div>
                    <div className="profile-info">roark@pipeag.com</div>

                    <div className="profile-small-label">Web Portal</div>
                    <div className="profile-info">https://www.pipeag.com</div>
                  </div>

                  <button className="btn me-5 w-100" onClick={() => useUIStore.setState({ showDeleteCoverageMapModal: true })}>
                    Delete Coverage Map
                  </button>
                </div>

                <div className="col-8">
                  <WeatherInfo />

                  <div className="accordion">
                    <Accordion title={"Dump Locations"} content={<DumpLocationList />} />
                    {/* {accordionData.map(({ title, content }) => (
                      <Accordion title={title} content={content} />
                    ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
