import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useState } from "react";

import useScheduleStore from "../../stores/scheduleStore";
import ScheduleList from "./ScheduleList";
import ScheduleForm from "./ScheduleForm";
import CrewMemberList from "./CrewMemberList";
import LocationList from "./LocationList";

export default function ScheduleTabs() {
  const [tabIndex, setTabIndex] = useState(0);

  const clearScheduleForm = () => {
    useScheduleStore.setState({
      showEditForm: false,
      showScheduleEquipmentForm: false,
      scheduleToEdit: null,
      showLocationForm: false,
      locationToEdit: null,
      showCrewMemberForm: false,
      crewMemberToEdit: null,
      currentEquipmentCrew: [],
    });
  };

  const setTabs = (index) => {
    setTabIndex(index);
    clearScheduleForm();
  };

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabs(index)}>
      <TabList>
        <Tab>All Schedules</Tab>
        <Tab>Create Schedule</Tab>
        <Tab>Crew Members</Tab>
        <Tab>Locations</Tab>
      </TabList>

      <TabPanel>
        <ScheduleList setTabIndex={setTabIndex} />
      </TabPanel>
      <TabPanel>
        <ScheduleForm />
      </TabPanel>
      <TabPanel>
        <CrewMemberList setTabIndex={setTabIndex} />
      </TabPanel>
      <TabPanel>
        <LocationList setTabIndex={setTabIndex} />
      </TabPanel>
    </Tabs>
  );
}
