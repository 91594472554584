import { useEffect, useState, useContext, useRef } from "react";

import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";
import { noIntercept } from "../lib/client";
import { MapContext } from "../components/MapLayer";

export default function DrawRoute() {
  const mapContext = useContext(MapContext);

  const showRoutesMenu = useUIStore((state) => state.showRoutesMenu);

  const updateCrewRoutes = useCrewStore((state) => state.updateCrewRoutes);
  const deleteCrewRoutes = useCrewStore((state) => state.deleteCrewRoutes);
  const crew = useCrewStore((state) => state.crew);

  const [allowDrawing, setAllowDrawing] = useState(false);
  const [showDrawingControls, setShowDrawingControls] = useState(false);
  const [saveOrRedraw, setSaveOrRedraw] = useState(false);

  const [routeDirection, setRouteDirection] = useState(null);
  const directionRef = useRef({});
  directionRef.current = routeDirection;

  // Coordinate markers of map drawing
  const [toRouteCoords, setToRouteCoords] = useState(null);
  // Polyline object of route
  const [toRoutePolyline, setToRoutePolyline] = useState(null);
  // The encoded string representation of the mapped route
  const [encodedTo, setEncodedTo] = useState(null);
  // Temporary plotted values of route
  const [tempToPlot, setTempToPlot] = useState(null);
  // Temporary coordinates of plotted route
  const [tempToCoords, setTempToCoords] = useState(null);
  // Temporary polyline of plotted route
  const [tempToRoutePolyline, setTempToRoutePolyline] = useState(null);
  // "Master" route - this is the one that is coming from the server on load and the finalized version after saving
  const [toPoly, setToPoly] = useState(null);

  const [fromRouteCoords, setFromRouteCoords] = useState(null);
  const [fromRoutePolyline, setFromRoutePolyline] = useState(null);
  const [encodedFrom, setEncodedFrom] = useState(null);
  const [tempFromPlot, setTempFromPlot] = useState(null);
  const [tempFromCoords, setTempFromCoords] = useState(null);
  const [tempFromRoutePolyline, setTempFromRoutePolyline] = useState(null);
  const [fromPoly, setFromPoly] = useState(null);
  const [highlightTarget, setHighlightTarget] = useState(null);

  // We have to wait for the maps code to load before we can plot stuff. This is a hack to get around the
  // map not being initialized on load and there being no trigger we can use to check for that
  useEffect(() => {
    if (crew && mapContext.map) {
      const interval = setInterval(() => {
        try {
          if (crew.to_route) {
            try {
              setToRouteCoords(window.google.maps.geometry.encoding.decodePath(crew.to_route));
              clearInterval(interval);
            } catch {}
          }

          if (crew.from_route) {
            try {
              setFromRouteCoords(window.google.maps.geometry.encoding.decodePath(crew.from_route));
              clearInterval(interval);
            } catch {}
          }
        } catch (e) {
          // Some error was found in the routes
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [crew, mapContext.map]);

  // When toRouteCoords or fromRouteCoords get set, this is saying we have a new finalized version of a route.
  // We want to redraw this finalized version onto the map.
  useEffect(() => {
    if (toRouteCoords) {
      drawSnappedPolyline(toRouteCoords, "to", false);
    }

    if (fromRouteCoords) {
      drawSnappedPolyline(fromRouteCoords, "from", false);
    }
  }, [toRouteCoords, fromRouteCoords]);

  // Logic that analyzses whether to show the drawing control buttons.
  // When showing the controls, we need to set the drawling layer on the map.
  // When hiding the controls, we need to remove the drawing layer.
  useEffect(() => {
    if (allowDrawing) {
      useUIStore.setState({ showMapControls: true });
      mapContext.drawingLayer.setMap(mapContext.map);
    } else {
      useUIStore.setState({ showMapControls: false });
      if (mapContext.drawingLayer) {
        mapContext.drawingLayer.setMap(null);
      }
    }
  }, [allowDrawing]);

  // This is similar to the allowDrawing change, but in the case where we do not show the
  // drawing controls at all, we need to reset the map entirely and remove all the drawing
  // and temporary routes the use may have potentially created.
  useEffect(() => {
    if (!showDrawingControls) {
      // Clear all plots and routes
      redrawRoutes();
      // Disable drawing controls
      if (mapContext.drawingLayer) {
        mapContext.drawingLayer.setMap(null);
      }
    }
  }, [showDrawingControls]);

  // Listener event that is attached to the map that gets fired whenever a route
  // is completed.
  const completeListener = async (poly) => {
    const path = poly.getPath();

    let pathValues = [];
    for (let i = 0; i < path.getLength(); i++) {
      pathValues.push(path.getAt(i).toUrlValue());
    }

    // We cannot use the `directionRef` variable directly here as this listener will retain
    // all the values as they were on initialization. The use of the `directionRef`  gets around
    // this to pull the current value.
    if (directionRef.current === "to") {
      setTempToPlot(pathValues);
      setToPoly(poly);
    } else {
      setTempFromPlot(pathValues);
      setFromPoly(poly);
    }

    setAllowDrawing(false);
  };

  // Initalizes the process of converted the coordinate points into polylines that are
  // snapped to actual roads on the map.
  const snapRoutesToRoad = () => {
    setHighlightTarget(null);
    if (tempToPlot == null || tempFromPlot == null) {
      alert("Please set both to and from routes");
    } else {
      roadResponse(tempToPlot, "to");
      roadResponse(tempFromPlot, "from");
      // Clear point markers from map
      toPoly.setMap(null);
      fromPoly.setMap(null);
      setSaveOrRedraw(true);
    }
  };

  // Ping Google to get the coordinates of the route as it's snapped to actual roads.
  const roadResponse = async (routePathValues, direction) => {
    const response = await noIntercept.get(
      `https://roads.googleapis.com/v1/snapToRoads?interpolate=true&path=${routePathValues.join(
        "|"
      )}&key=AIzaSyClTW9YIx-S0Ilx6ggcYOZREZcplwQlvlg`
    );

    processSnapToRoadResponse(response.data, direction);
  };

  // Process the data from Google and store the encoded string of the polyline.
  const processSnapToRoadResponse = (data, direction) => {
    let snappedCoordinates = [];
    let placeIdArray = [];
    for (let i = 0; i < data.snappedPoints.length; i++) {
      var latlng = new window.google.maps.LatLng(data.snappedPoints[i].location.latitude, data.snappedPoints[i].location.longitude);
      snappedCoordinates.push(latlng);
      placeIdArray.push(data.snappedPoints[i].placeId);
    }

    direction === "to" ? setTempToCoords(snappedCoordinates) : setTempFromCoords(snappedCoordinates);

    let encoded = window.google.maps.geometry.encoding.encodePath(snappedCoordinates);
    direction === "to" ? setEncodedTo(encoded) : setEncodedFrom(encoded);

    const lineColor = direction === "to" ? "#32CD32" : "#FF0000";
    drawSnappedPolyline(snappedCoordinates, direction, lineColor);
  };

  // Draws the snapped polyline (after processing snap-to-road response).
  const drawSnappedPolyline = (coordinates, direction, newRoute = true, color = null) => {
    var snappedPolyline = new window.google.maps.Polyline({
      path: coordinates,
      icons: [
        {
          icon: { path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
          offset: "100%",
        },
      ],
      strokeColor: !!color ? color : direction === "to" ? "#32CD32" : "#FF0000",
      strokeWeight: 4,
      strokeOpacity: 0.9,
    });

    if (newRoute) {
      direction === "to" ? setTempToRoutePolyline(snappedPolyline) : setTempFromRoutePolyline(snappedPolyline);
    } else {
      direction === "to" ? setToRoutePolyline(snappedPolyline) : setFromRoutePolyline(snappedPolyline);
    }

    snappedPolyline.setMap(mapContext.map);
  };

  // Clears all markers and routes
  const redrawRoutes = () => {
    try {
      if (tempToRoutePolyline) {
        tempToRoutePolyline.setMap(null);
      }

      if (toPoly) {
        toPoly.setMap(null);
      }

      setTempToPlot(null);
      setTempToRoutePolyline(null);
      setToPoly(null);

      if (tempFromRoutePolyline) {
        tempFromRoutePolyline.setMap(null);
      }

      if (fromPoly) {
        fromPoly.setMap(null);
      }

      setTempFromPlot(null);
      setTempFromRoutePolyline(null);
      setFromPoly(null);

      setSaveOrRedraw(false);
      setRouteDirection("to");
    } catch {
      console.log("errors");
    }
  };

  // Saves the newly drawn routes, clears all existing lines and coordinates
  const saveRoutes = () => {
    updateCrewRoutes({ toRoute: encodedTo, fromRoute: encodedFrom });

    if (toRoutePolyline) {
      toRoutePolyline.setMap(null);
    }

    if (tempToRoutePolyline) {
      tempToRoutePolyline.setMap(null);
    }

    if (fromRoutePolyline) {
      fromRoutePolyline.setMap(null);
    }

    if (tempFromRoutePolyline) {
      tempFromRoutePolyline.setMap(null);
    }

    setToRouteCoords(tempToCoords);
    setFromRouteCoords(tempFromCoords);
    setShowDrawingControls(false);
  };

  const confirmDeleteRoutes = () => {
    window.confirm("Delete all routes?", () => deleteRoutes());
  };

  // Deletes all routes and clears the map
  const deleteRoutes = () => {
    deleteCrewRoutes();

    if (toRoutePolyline) {
      toRoutePolyline.setMap(null);
      setToRouteCoords(null);
      setToRoutePolyline(null);
    }

    if (fromRoutePolyline) {
      fromRoutePolyline.setMap(null);
      setFromRouteCoords(null);
      setFromRoutePolyline(null);
    }

    redrawRoutes();

    setAllowDrawing(false);
    setSaveOrRedraw(false);
  };

  useEffect(() => {
    if (mapContext.map && mapContext.drawingLayer) {
      mapContext.drawingLayer.addListener("polylinecomplete", (poly) => completeListener(poly));
    }
  }, [mapContext.map, mapContext.drawingLayer]);

  return (
    showRoutesMenu && (
      <div className="right-drawer routes-menu">
        <div className="action-list">
          {!showDrawingControls && !saveOrRedraw && (
            <>
              <div className="action" onClick={() => setShowDrawingControls(true)}>
                <div className="name">Draw New Routes</div>
              </div>
              <div className="action" onClick={confirmDeleteRoutes}>
                <div className="name red">Delete ALL Routes</div>
              </div>
            </>
          )}

          {showDrawingControls && !saveOrRedraw && (
            <>
              <div
                className={`action ${highlightTarget === "to-route" ? "highlight-route" : ""}`}
                onClick={() => {
                  setRouteDirection("to");
                  setHighlightTarget("to-route");
                  setAllowDrawing(true);
                  if (toPoly) {
                    toPoly.setMap(null);
                    setToPoly(null);
                  }
                }}>
                <div className="name">Set To Route</div>
              </div>
              <div
                className={`action ${highlightTarget === "from-route" ? "highlight-route" : ""}`}
                onClick={() => {
                  setRouteDirection("from");
                  setHighlightTarget("from-route");
                  setAllowDrawing(true);
                  if (fromPoly) {
                    fromPoly.setMap(null);
                    setFromPoly(null);
                  }
                }}>
                <div className="name">Set From Route</div>
              </div>
              <div className="action" onClick={snapRoutesToRoad}>
                <div className="name">Process Routes</div>
              </div>
              <div
                className="action"
                onClick={() => {
                  setShowDrawingControls(false);
                  setAllowDrawing(false);
                  setSaveOrRedraw(false);
                }}>
                <div className="name">Cancel</div>
              </div>
            </>
          )}

          {saveOrRedraw && (
            <>
              <div className="action" onClick={saveRoutes}>
                <div className="name">Save Routes</div>
              </div>
              <div className="action" onClick={redrawRoutes}>
                <div className="name">Redraw Routes</div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
}
