import { useContext } from "react";

import { MapContext } from "../components/MapLayer";
import useEquipmentStore from "../stores/equipmentStore";
import { parseLatLong } from "../lib/parsing";

export default function ShowAllEquipment() {
  const mapContext = useContext(MapContext);
  const crewEquipment = useEquipmentStore((state) => state.crewEquipment);

  // Just test data
  // const x = [
  //   [-86.95289732811862, 42.75752256708432],
  //   [-89.03743065869877, 42.81613596591899],
  //   [-89.91996296915502, 41.57361415972224],
  //   [-89.16122478369476, 40.58631018169214],
  //   [-87.1405851950483, 40.531699960712444],
  //   [-85.72294279589889, 41.6810726975597],
  //   [-86.90497702166857, 42.725261542510964],
  // ];

  const fitCrew = () => {
    const bounds = new window.google.maps.LatLngBounds();

    // Just test data
    // for (let i = 0; i < x.length; i++) {
    //   bounds.extend({ lat: x[i][1], lng: x[i][0] });
    // }

    crewEquipment.forEach((e) => (e.last_location ? bounds.extend(parseLatLong(e.last_location)) : ""));
    mapContext.map.fitBounds(bounds);
  };

  return (
    <div onClick={fitCrew}>
      <i className="fa-solid fa-arrows-up-down-left-right"></i> GO TO CREW
    </div>
  );
}
