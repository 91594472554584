import create from "zustand";
import { devtools } from "zustand/middleware";

import client from "../lib/client";
import { customerFieldsUrl, crewFieldsUrl, updateAssignedFieldsUrl, farmFieldsUrl, deleteCoverageMapUrl } from "../lib/endpoints";
import { processFieldFeatures, getFieldFeatures } from "../lib/field";
import { setReloadTimestamp } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useFieldStore = (set) => ({
  fields: [],
  customerFields: {},
  farmFields: [],
  crewFields: [],
  crewFieldsIds: [],
  tempCrewFields: [],
  crewFieldsFeatures: [],
  crewFieldsCoordinates: [],
  assigningFields: false,
  tempAssignedFieldsIds: [],
  tempAssignedFields: [],
  fieldEntryPoints: [],
  loading: false,
  hasErrors: false,
  // fetchFields: async () => {
  //   set(() => ({ loading: true }));
  //   try {
  //     const response = await client.get(fieldsUrl);
  //     set((state) => ({ fields: response.data.dump_locations, loading: false }));
  //   } catch (err) {
  //     set(() => ({ hasErrors: true, loading: false }));
  //   }
  // },
  fetchCustomerFields: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(customerFieldsUrl);
      set((state) => ({ customerFields: response.data.customer_fields, loading: false }));
      // const processResponse = processFieldFeatures(response.data.crew_fields);
      // set((state) => ({ crewFieldsFeatures: processResponse.fieldFeatures }));
      // set((state) => ({ crewFieldsCoordinates: processResponse.coords }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchCrewFields: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(crewFieldsUrl);
      set((state) => ({ crewFields: response.data.crew_fields, loading: false }));
      const processResponse = processFieldFeatures(response.data.crew_fields);
      set((state) => ({ crewFieldsFeatures: processResponse.fieldFeatures }));
      set((state) => ({ crewFieldsCoordinates: processResponse.coords }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchCustomerFarmFields: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(farmFieldsUrl);
      set((state) => ({ farmFields: response.data.farm_fields, loading: false }));
      // const processResponse = processFieldFeatures(response.data.crew_fields);
      // set((state) => ({ crewFieldsFeatures: processResponse.fieldFeatures }));
      // set((state) => ({ crewFieldsCoordinates: processResponse.coords }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  setTempCrewFields: async (values) => {
    let tempFields = [];
    if (values.field_ids.length > 0) {
      Object.keys(useFieldStore.getState().customerFields).map((key) => {
        useFieldStore.getState().customerFields[key].fields.map((field) => {
          if (values.field_ids.includes(field.id.toString())) {
            tempFields.push(field);
          }
        });
      });
    }
    set(() => ({ tempAssignedFieldsIds: values.field_ids, tempAssignedFields: tempFields, assigningFields: true }));
  },
  updateAssignedFields: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(updateAssignedFieldsUrl, { field_ids: useFieldStore.getState().tempAssignedFieldsIds });
      if (response.data.success) {
        setReloadTimestamp(new Date());
        window.location.reload(false);
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  deleteCoverageMapData: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(deleteCoverageMapUrl, { ...values });
      if (response.data.success) {
        set(() => ({ loading: false }));
        return true;
      } else {
        set(() => ({ loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
});

useFieldStore = devtools(useFieldStore, { name: "FieldStore" });

export default useFieldStore = create(useFieldStore);
