import { useState } from "react";
import ReactModal from "react-modal";

import useUIStore from "../../stores/uiStore";
import useScheduleStore from "../../stores/scheduleStore";
import VerifyPINForm from "./VerifyPINForm";
import ScheduleTabs from "./ScheduleTabs";

export default function ScheduleModal() {
  const showScheduleModal = useUIStore((state) => state.showScheduleModal);
  const verifyPIN = useScheduleStore((state) => state.verifyPIN);

  const closeModal = () => {
    useUIStore.setState({ showScheduleModal: !showScheduleModal });
    useScheduleStore.setState({
      showEditForm: false,
      showScheduleEquipmentForm: false,
      scheduleToEdit: null,
      showCrewMemberForm: false,
      crewMemberToEdit: null,
    });
  };

  return (
    <>
      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showScheduleModal}
        // onAfterOpen={
        //   // TODO: WIRE THIS UP TO ACTUAL CUSTOMER - CAN JUST REMOVE AND MAKE IT FROM DEVICE ID
        //   () => console.log("test")
        //   /* Function that will be run after the modal has opened. */
        // }
        contentLabel="Minimal Modal Example"
        style={{
          overlay: {
            // backgroundColor: "papayawhip",
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Schedule</h4>
            <button onClick={closeModal}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <div className="modal-content">{verifyPIN ? <VerifyPINForm /> : <ScheduleTabs />}</div>
        </div>
      </ReactModal>
    </>
  );
}
