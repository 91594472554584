import { useEffect, useState } from "react";

import useScheduleStore from "../../stores/scheduleStore";
import Location from "./Location";
import LocationForm from "./LocationForm";

export default function LocationList({ setTabIndex }) {
  const fetchLocations = useScheduleStore((state) => state.fetchLocations);
  const locations = useScheduleStore((state) => state.locations);
  const locationToEdit = useScheduleStore((state) => state.locationToEdit);
  const showLocationForm = useScheduleStore((state) => state.showLocationForm);
  const loading = useScheduleStore((state) => state.loading);

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (!showLocationForm) {
      fetchLocations();
    }
  }, [showLocationForm, fetchLocations]);

  const renderLocations = () => {
    return locations.map((location) => <Location key={location.id} value={location.id} location={location} />);
  };

  const renderForm = () => {
    if (showLocationForm) {
      return <LocationForm location={locationToEdit} editing={true} setTabIndex={setTabIndex} />;
    }
  };

  return (
    <>
      {!showLocationForm ? (
        <>
          <button onClick={() => useScheduleStore.setState({ showLocationForm: true })} className="btn-sm btn-primary float-right">
            Create Location
          </button>
          <table className="w-100 text-center" style={{ fontSize: "25px" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderLocations()}</tbody>
          </table>
        </>
      ) : (
        renderForm()
      )}
    </>
  );
}
