import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { debounce } from "lodash";

import useGasTicketStore from "../../stores/gasTicketStore";
import useLocationStore from "../../stores/locationStore";
import { gasTicketsUrl } from "../../lib/endpoints";
import { watchFocus } from "../../lib/form";
import { geoLookup } from "../../lib/geocode";

export default function GasTicketForm() {
  const createGasTicket = useGasTicketStore((state) => state.createGasTicket);
  const loading = useGasTicketStore((state) => state.loading);
  const lat = useLocationStore((state) => state.myLocation?.lat);
  const lng = useLocationStore((state) => state.myLocation?.lng);

  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    // don't run if no location data is available
    if (!lat || !lng) {
      console.log("No lat/lng data availalbe to run geocode lookup.");
      return;
    }

    // don't run if we already have city/state
    if (city || state) {
      console.log("Already have geo lookup response... skipping lookup");
      return;
    }

    try {
      getGeo();
    } catch (e) {
      console.log("Error performing geo lookup: ", e);
    }
  }, [city, state, lat, lng]);

  /**
   * Look up Google Maps geolocation data based on lat/lng in locationStore.
   *
   * This will set the city/state component state values based on the 'locality' response.
   */
  const getGeo = debounce(() => {
    geoLookup(lat, lng, ({ city, state }) => {
      setCity(city);
      setState(state);
    });
  }, 250);

  useEffect(() => {}, []);

  // if we want async logic
  // const handleSubmit = async (values, setSubmitting) => {
  //   setSubmitting(false);
  //   const x = await createGasTicket(values);
  //   alert(x);
  // };

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(false);
    createGasTicket(values);
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{ city: city || "", state: state || "", gallons: "0", amount: "0", odometer: "0" }}
        validate={(values) => {
          const errors = {};
          if (!values.city) {
            errors.city = "Required";
          }
          if (!values.state) {
            errors.state = "Required";
          }
          if (!values.gallons || values.gallons === "0") {
            errors.gallons = "Required";
          }
          if (!values.amount || values.amount === "0") {
            errors.amount = "Required";
          }
          if (!values.odometer || values.odometer === "0") {
            errors.odometer = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}>
        {({ isSubmitting }) => (
          <Form>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="state">
                    State
                  </label>
                  <Field name="state" as="select">
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Field>
                  <ErrorMessage name="state" component="div" className="formik-error" />
                </div>
                <div className="col">
                  <label className="formik-label" htmlFor="city">
                    City
                  </label>
                  <Field name="city" label="City" type="text" />
                  <ErrorMessage name="city" component="div" className="formik-error" />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="gallons">
                    Total Gallons
                  </label>
                  <Field name="gallons" label="gallons" type="number" innerRef={watchFocus} />
                  <ErrorMessage name="gallons" component="div" className="formik-error" />
                </div>

                <div className="col">
                  <label className="formik-label" htmlFor="amount">
                    Total Amount Paid
                  </label>
                  <Field name="amount" label="amount" type="number" innerRef={watchFocus} />
                  <ErrorMessage name="amount" component="div" className="formik-error" />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="odometer">
                    Odometer
                  </label>
                  <Field name="odometer" label="odometer" type="number" innerRef={watchFocus} />
                  <ErrorMessage name="odometer" component="div" className="formik-error" />
                </div>
              </div>
            </div>
            <div className="action-stuck-bottom">
              <button type="submit" disabled={loading} className="btn btn-primary">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
