import create from "zustand";
import { devtools } from "zustand/middleware";
import AWN from "awesome-notifications";
import moment from "moment";

import client from "../lib/client";
import { chatUrl } from "../lib/endpoints";
import useCrewStore from "./crewStore";
import useEquipmentStore from "./equipmentStore";

const chatAudioPing = new Audio("chat_ping.mp3");

let useChatStore = (set) => ({
  /**
   * Array of chat messages
   */
  messages: [],
  /**
   * Loading flag
   */
  loading: false,
  /**
   * Network/misc errors
   */
  hasErrors: false,
  /**
   * Is the chat container open?
   */
  open: false,
  /**
   * The new message being typed by the user
   */
  newMessage: "",
  /**
   * Are there unread messages?
   */
  unread: false,
  /**
   * Load messages from the server
   */
  fetchMessages: async (crewId) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(chatUrl(crewId));
      set((state) => ({ messages: response.data.messages, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  /**
   * Handle opening/closing the chat container to view messages
   */
  toggleChat: async () => {
    set((state) => ({ open: !state.open, unread: false }));
  },
  /**
   * Handle user's input in the chat input
   */
  setNewMessage: async (message) => {
    set((state) => ({ newMessage: message }));
  },
  /**
   * Respond to a broadcast for a new message from ActionCable.
   */
  addMessage: async (message) => {
    // along with setting the messages, also mark messages as unread as long as the chat isn't currently open.
    set((state) => ({ messages: state.messages.concat(message), unread: !state.open }));
    if (message.equipment_id !== useEquipmentStore.getState().equipment?.id) {
      chatAudioPing.play();
      new AWN().info(`${message.equipment_name} @ ${moment(message.created_at).format("h:mma")}`, {
        position: "top-right",
        durations: { info: 300000 },
        labels: { info: message.message },
        icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
      });
    }
  },
});

/**
 * Post a new message to the server
 */
export const submitMessage = async () => {
  const newMessage = useChatStore.getState().newMessage;
  const crewId = useCrewStore.getState().crew?.id;

  useChatStore.setState({ loading: false, newMessage: "" });

  try {
    const response = await client.post(chatUrl(crewId), {
      chat_message: {
        message: newMessage,
      },
    });
  } catch (e) {
    useChatStore.setState({ hasErrors: true, loading: false });
  }
};

useChatStore = devtools(useChatStore, { name: "ChatStore" });

export default useChatStore = create(useChatStore);
