import MyTruck from "./MyTruck";
import Truck from "./Truck";
import useEquipmentStore from "../stores/equipmentStore";

export default function TruckCards() {
  const nearbyEquipment = useEquipmentStore((state) => state.nearbyEquipment);
  const percentFull = useEquipmentStore((state) => state.percentFull);

  // ----------------------------------------------------------------------------------------
  // Style: _truck.scss
  // ----------------------------------------------------------------------------------------
  // TODO the idea here is to render out whatever components we need with whatever data.
  // without knowing exactly where that data comes from, i just hard coded the props for now.
  // ----------------------------------------------------------------------------------------
  // TODO idk how it should render if we only need 2 cards or if we always have 3, etc.
  // a pass through on that would probably be good.
  // ----------------------------------------------------------------------------------------

  const renderNearbyEquipment = (equipment) => {
    if (equipment != null) {
      return equipment.map((e) => (
        <Truck
          key={e.id}
          name={e.name}
          speed={`${Math.round(parseFloat(e.last_speed) * 10) / 10}MPH`}
          progress={e.fill_percent}
          // time="0:00"
        />
      ));
    }
  };

  return (
    <div className="truck-cards">
      {/* <Truck name="Truck 5" speed="3.6mph" time="0:00" />
      <Truck name="Truck 1" speed="3.7mph" time="1:43" /> */}

      {renderNearbyEquipment(nearbyEquipment)}

      {/* <MyTruck speedNumber={percentFull} speedUnit="mph" /> */}

      <MyTruck />
    </div>
  );
}
