import useChatStore from "../stores/chatStore";
import useUIStore from "../stores/uiStore";

export default function ChatIcon() {
  const showChat = useChatStore((state) => state.open);
  const unread = useChatStore((state) => state.unread);
  const showSecondaryRightMenu = useUIStore((state) => state.showSecondaryRightMenu);
  const openMenuItem = useUIStore((state) => state.openMenuItem);

  const handleOpen = () => {
    openMenuItem("chat");
  };

  return (
    <button
      className={`btn ${showChat ? "active" : ""} ${unread ? "chat-unread" : ""}`}
      onClick={handleOpen}
      disabled={showSecondaryRightMenu}>
      <i className="fa-solid fa-message"></i>
    </button>
  );
}
