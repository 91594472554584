export default function Truck({ name, speed, time, progress }) {
  const statusColor = (percentage) => {
    if (percentage < 51) {
      return "#96E813";
    } else if (percentage >= 51 && percentage < 80) {
      return "#F08214";
    } else {
      return "#F01414";
    }
  };

  return (
    <div className="truck">
      <div className="truck-card-details">
        <div className="left">
          <div className="name">{name}</div>
          <div className="speed">{speed}</div>
          <div className="time">{time}</div>
        </div>
        {/* <div className="right">
          <button className="load-btn">FULL LOAD</button>
          <div className="other-link">Other Amount</div>
        </div> */}
      </div>
      <div className="truck-progress">
        <div className="truck-progress-status" style={{ width: `${progress}%`, backgroundColor: statusColor(progress) }}></div>
      </div>
    </div>
  );
}
