import React from "react";
import { removeAllStorage, setReloadTimestamp } from "./lib/storage";

export default function ErrorScreen() {
  const reload = () => {
    setReloadTimestamp(new Date());
    window.location.reload();
  };

  const reloadAndDeleteData = () => {
    removeAllStorage();
    setReloadTimestamp(new Date());
    window.location.reload();
  };

  return (
    <div className="error-screen">
      <h1>An Error has Occurred.</h1>
      <h2>Please press Reload below to reload the app.</h2>
      <button className="btn" onClick={reload}>
        Reload Only
      </button>

      <br />
      <br />
      <br />
      <br />

      <button className="btn" onClick={reloadAndDeleteData}>
        Reload and delete all app data (helpful if crashing persists)
      </button>
    </div>
  );
}
