import { useContext, useEffect } from "react";
import { styleMap } from "../lib/map";

import useFieldStore from "../stores/fieldStore";
import Field from "./Field";
import { MapContext } from "./MapLayer";

export default function FieldRenderer() {
  const mapContext = useContext(MapContext);

  const crewFields = useFieldStore((state) => state.crewFields);

  useEffect(() => {
    if (crewFields && mapContext.map && mapContext.fieldLayer) {
      renderFieldsOnMap();
    }
  }, [crewFields, mapContext.map, mapContext.fieldLayer]);

  const renderFieldsOnMap = () => {
    crewFields.forEach(function (f) {
      // -----------------------------------------------------------------------------------------------------
      // when you add geo json, it returns an array of Feature objects which can be looped over
      // so you can set properties on the features that were just added (type, etc).
      // -----------------------------------------------------------------------------------------------------
      // https://developers.google.com/maps/documentation/javascript/reference/data#Data.addGeoJson
      // https://developers.google.com/maps/documentation/javascript/reference/data#Data.Feature.setProperty
      // -----------------------------------------------------------------------------------------------------
      // mapContext.map.data.addGeoJson(f.geojson).forEach((feature) => {
      //   feature.setProperty("type", "field");
      // });

      // styleMap(mapContext.map);

      mapContext.fieldLayer.addGeoJson(f.geojson);
    });

    mapContext.fieldLayer.setStyle({
      fillColor: "white",
      strokeWeight: 1,
      strokeColor: "white",
      clickable: false,
    });
  };

  // const renderFields = () => {
  //   if (crewFields) {
  //     return crewFields.map((f, i) => {
  //       return <Field key={i} field={f} />;
  //     });
  //   }
  // };

  // return (
  //   <div>
  //     <h3>Fields (click to center)</h3>
  //     {renderFields()}
  //   </div>
  // );
}
