import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";
import HeaderInfo from "./HeaderInfo";
import Chat from "./Chat";

export default function HeaderBar() {
  const showRightMenu = useUIStore((state) => state.showRightMenu);
  const showSecondaryRightMenu = useUIStore((state) => state.showSecondaryRightMenu);
  const showTicketMenu = useUIStore((state) => state.showTicketMenu);
  const showRoutesMenu = useUIStore((state) => state.showRoutesMenu);
  const openMenuItem = useUIStore((state) => state.openMenuItem);
  const setTextSize = useUIStore((state) => state.setTextSize);
  const crew = useCrewStore((state) => state.crew);

  return (
    <div className="header-bar">
      <div className="left">
        <button className="btn" onClick={setTextSize}>
          <i className="fa-solid fa-text-size"></i>
        </button>
      </div>
      <div className="center">
        <HeaderInfo />
      </div>
      <div className="right">
        {crew && (
          <>
            <button
              disabled={showSecondaryRightMenu}
              className={`btn ${showTicketMenu ? "active" : ""}`}
              onClick={() => openMenuItem("tickets")}>
              <i className="fa-solid fa-receipt"></i>&nbsp;&nbsp;Create Ticket
            </button>

            <button
              disabled={showSecondaryRightMenu}
              className={`btn ${showRoutesMenu ? "active" : ""}`}
              onClick={() => openMenuItem("routes")}>
              <i className="fa fa-exchange"></i>
            </button>

            <Chat />
          </>
        )}
        <button
          disabled={showSecondaryRightMenu}
          className={`btn ${showRightMenu ? "active" : ""}`}
          onClick={() => openMenuItem("sidebar")}>
          <i className="fa-light fa-bars"></i>
        </button>
      </div>
    </div>
  );
}
