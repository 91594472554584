import useScheduleStore from "../../stores/scheduleStore";

export default function CrewMember({ crewMember }) {
  const formatPermissions = () => {
    let permissionArray = [];
    if (crewMember.allow_trucks) permissionArray.push("Trucks");
    if (crewMember.allow_combines) permissionArray.push("Combines");
    if (crewMember.allow_grain_carts) permissionArray.push("Grain Carts");
    return permissionArray.join(", ");
  };

  return (
    <tr>
      <td>
        {crewMember.first_name} {crewMember.last_name}
      </td>
      <td>{formatPermissions()}</td>
      <td>
        <button
          className="btn"
          onClick={() => {
            useScheduleStore.setState({
              showCrewMemberForm: true,
              crewMemberToEdit: crewMember,
            });
          }}>
          <i className="fa-solid fa-pencil"></i>
        </button>
      </td>
    </tr>
  );
}
