import create from "zustand";
import { devtools } from "zustand/middleware";

import client from "../lib/client";
import { getDeviceId } from "../lib/storage";
import { truckTypes } from "../lib/equipmentTypes";
import {
  customerEquipmentUrl,
  crewEquipmentUrl,
  crewTrucksUrl,
  equipmentLocationUrl,
  equipmentShowUrl,
  equipmentStatusUrl,
  equipmentHasCrewUrl,
} from "../lib/endpoints";
import { setReloadTimestamp } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useEquipmentStore = (set) => ({
  animateMarkers: false,
  equipment: null,
  customerEquipment: [],
  customerChoppers: [],
  crewEquipment: [],
  crewEquipmentIds: [],
  originalCrewEquipmentIds: [],
  crewTrucks: [],
  deviceName: null,
  equipmentType: null,
  loading: false,
  hasErrors: false,
  sortEquipmentByName: "az",
  sortEquipmentByDistance: null,
  // Filling truck data
  equipmentStatus: null,
  equipmentStatusQueue: [],
  percentFull: null,
  currentlyFilling: false,
  activeChopper: null,
  focusedEquipment: null,
  lockToEquipment: null,
  nearbyEquipment: [],

  fetchEquipment: async (deviceId) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(equipmentShowUrl(deviceId));
      set((state) => ({
        deviceName: response.device_name,
        equipmentStatus: response.location_status,
        equipmentType: response.equipment_type,
      }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  updateEquipment: async (values, reload = false) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(equipmentShowUrl(getDeviceId()), { equipment: { ...values } });
      if (reload && response.data.success === true) {
        setReloadTimestamp(new Date());
        window.location.reload(false);
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchCustomerEquipment: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(customerEquipmentUrl);
      set((state) => ({ customerEquipment: response.data.customer_equipment, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchCrewEquipment: async (customerId) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(crewEquipmentUrl);
      set((state) => ({ crewEquipment: response.data.crew_equipment, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchCrewTrucks: async (customerId) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(crewTrucksUrl);
      set((state) => ({ crewTrucks: response.data.crew_trucks, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  updateEquipmentLocation: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(equipmentLocationUrl, { ...values });
      set((state) => ({ loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  updateEquipmentStatus: async (status, isQueueStatus = false) => {
    if (status === "RD" && truckTypes.includes(useEquipmentStore.getState().equipmentType)) {
      set(() => ({ loading: true, equipmentStatus: status, percentFull: 100 }));
    } else if ((status === "RF" || status === "F") && truckTypes.includes(useEquipmentStore.getState().equipmentType)) {
      set(() => ({ loading: true, equipmentStatus: status, percentFull: 0 }));
    } else {
      set(() => ({ loading: true, equipmentStatus: status }));
    }

    try {
      const response = await client.post(equipmentStatusUrl, { equipment: { location_status: status } });
      if (response.data.success) {
        if (isQueueStatus) {
          set((state) => ({ loading: false, equipmentStatusQueue: state.equipmentStatusQueue.slice(1) }));
        } else {
          set((state) => ({ loading: false }));
        }
      }
    } catch (err) {
      // If this is a queue status, we don't want to duplicate it
      if (!isQueueStatus) {
        set((state) => ({ hasErrors: true, loading: false, equipmentStatusQueue: [...state.equipmentStatusQueue, status] }));
      } else {
        set((state) => ({ hasErrors: true, loading: false }));
      }
    }
  },
  updateEquipmentLoadingTimestamp: async (timestamp) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(equipmentStatusUrl, { equipment: { started_loading_at: timestamp } });
      set((state) => ({ loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  sortCrewEquipmentByName: () => {
    const sortByName = useEquipmentStore.getState().sortEquipmentByName;
    switch (sortByName) {
      case "az":
        set((state) => ({ sortEquipmentByName: "za", sortEquipmentByDistance: null }));
        break;
      case "za":
        set((state) => ({ sortEquipmentByName: "az", sortEquipmentByDistance: null }));
        break;
      default:
        set((state) => ({ sortEquipmentByName: "az", sortEquipmentByDistance: null }));
    }
  },
  sortCrewEquipmentByDistance: () => {
    const sortByDistance = useEquipmentStore.getState().sortEquipmentByDistance;
    switch (sortByDistance) {
      case "nearest":
        set((state) => ({ sortEquipmentByName: null, sortEquipmentByDistance: "farthest" }));
        break;
      case "farthest":
        set((state) => ({ sortEquipmentByName: null, sortEquipmentByDistance: "nearest" }));
        break;
      default:
        set((state) => ({ sortEquipmentByName: null, sortEquipmentByDistance: "nearest" }));
    }
  },
  updateMyEquipmentLocation: async (location) => {
    set((state) => ({ equipment: { ...state.equipment, last_location: location } }));
  },
});

// If this current device does not have a crew assigned, ping and fetch if it has been assigned to one.
// If it has been assigned to a crew, refresh the app
export const processEquipmentAssignment = async () => {
  if (useEquipmentStore.getState().equipment != null && useEquipmentStore.getState().equipment.crew_id == null) {
    const response = await client.get(equipmentHasCrewUrl);
    if (response.data.reload) {
      setReloadTimestamp(new Date());
      window.location.reload(false);
    }
  }
  setTimeout(processEquipmentAssignment, 30000);
};

export const processEquipmentStatusQueue = async () => {
  const queue = useEquipmentStore.getState().equipmentStatusQueue;
  if (queue.length > 0) {
    await useEquipmentStore.getState().updateEquipmentStatus(queue[0], true);
  }
  setTimeout(processEquipmentStatusQueue, 15000);
};

useEquipmentStore = devtools(useEquipmentStore, { name: "EquipmentStore" });

export default useEquipmentStore = create(useEquipmentStore);
