import { useState } from "react";
import ReactModal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useFieldStore from "../stores/fieldStore";
import useUIStore from "../stores/uiStore";

export default function DeleteCoverageMapModal() {
  const showDeleteCoverageMapModal = useUIStore((state) => state.showDeleteCoverageMapModal);
  const deleteCoverageMapData = useFieldStore((state) => state.deleteCoverageMapData);

  const [showError, setShowError] = useState(false);

  const closeModal = () => {
    useUIStore.setState({ showDeleteCoverageMapModal: !showDeleteCoverageMapModal });
  };

  return (
    <>
      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showDeleteCoverageMapModal}
        // onAfterOpen={
        //   // TODO: WIRE THIS UP TO ACTUAL CUSTOMER - CAN JUST REMOVE AND MAKE IT FROM DEVICE ID
        //   () => console.log("test")
        //   /* Function that will be run after the modal has opened. */
        // }
        contentLabel="Minimal Modal Example"
        style={{
          overlay: {
            // backgroundColor: "papayawhip",
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Delete Coverage Map Data</h4>
            <button onClick={closeModal}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <div className="modal-content">
            <Formik
              enableReinitialize={true}
              initialValues={{ pin: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.pin || values.pin === "") {
                  errors.device_name = "Required";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                const success = await deleteCoverageMapData(values);
                if (!success) {
                  setShowError(true);
                } else {
                  alert("Deleted coverage map data");
                  useUIStore.setState({ showDeleteCoverageMapModal: false });
                }
              }}>
              {({ isSubmitting }) => (
                <>
                  <h3 className="text-center orange">Enter PIN</h3>
                  <p className="text-center orange">To delete current coverage map data, please enter your assigned PIN.</p>
                  <Form>
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <label className="formik-label" htmlFor="pin">
                            PIN
                          </label>
                          <Field name="pin" label="pin" type="text" />
                          <ErrorMessage name="pin" component="div" className="formik-error" />

                          {showError && (
                            <div>
                              <p className="red">Could not verify this PIN</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="action-stuck-bottom">
                      <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                        Delete Coverage Map Data
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
