import useAppStore from "../stores/appStore";
import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";
import useEquipmentStore from "../stores/equipmentStore";

export default function CrewDrawerHead() {
  const crewName = useCrewStore((state) => state.crew?.name);
  const connected = useAppStore((state) => state.connected);
  const hideCrewDrawer = useUIStore((state) => state.hideCrewDrawer);
  const sortByName = useEquipmentStore((state) => state.sortCrewEquipmentByName);
  const sortByDistance = useEquipmentStore((state) => state.sortCrewEquipmentByDistance);
  const sortEquipmentByName = useEquipmentStore((state) => state.sortEquipmentByName);
  const sortEquipmentByDistance = useEquipmentStore((state) => state.sortEquipmentByDistance);

  const renderAZSortLabel = () => {
    if (sortEquipmentByName) {
      if (sortEquipmentByName === "az") {
        return "A-Z";
      } else {
        return "Z-A";
      }
    } else {
      return "A-Z";
    }
  };

  const renderDistanceSortLabel = () => {
    if (sortEquipmentByDistance) {
      if (sortEquipmentByDistance === "nearest") {
        return "Nearest";
      } else {
        return "Farthest";
      }
    } else {
      return "Distance";
    }
  };

  const showHideCrewList = () => {
    hideCrewDrawer ? useUIStore.setState({ hideCrewDrawer: false }) : useUIStore.setState({ hideCrewDrawer: true });
  };

  return (
    <div className={`drawer-head ${connected !== 1 ? "disconnected" : ""}`}>
      <div className="heading flex">
        <div className="head-title flex-1" id="head-title">
          {connected === 1 ? `${crewName != undefined ? crewName : ""}` : `${crewName != undefined ? crewName : ""} (no internet)`}
        </div>
        <div className="flex-1 text-right">
          {/* {connected === 1 ? "Crew" : "Crew (no internet)"} */}
          <button onClick={showHideCrewList} className="btn crew-toggle">
            <i className={`fa-regular fa-${hideCrewDrawer ? "chevron-right" : "chevron-down"}`}></i>
          </button>
        </div>
      </div>
      {!hideCrewDrawer && (
        <div className="filters">
          <div className="btn-group sort">
            <div className={`btn alpha ${sortEquipmentByName ? "active" : ""}`} id="sort-alpha" onClick={sortByName}>
              {renderAZSortLabel()}
            </div>
            <div className={`btn alpha ${sortEquipmentByDistance ? "active" : ""}`} id="sort-value" onClick={sortByDistance}>
              {renderDistanceSortLabel()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
