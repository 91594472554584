import { Formik, Form, Field, ErrorMessage } from "formik";
import AWN from "awesome-notifications";

import "react-datepicker/dist/react-datepicker.css";

import useScheduleStore from "../../stores/scheduleStore";

export default function CrewMemberForm({ crewMember = null, editing = false, setTabIndex }) {
  const updateCrewMember = useScheduleStore((state) => state.updateCrewMember);
  const loading = useScheduleStore((state) => state.loading);

  const initialValues = {
    allow_trucks: false,
    allow_combines: false,
    allow_grain_carts: false,
  };

  const checkOrUncheckAll = (checked, setFieldValue) => {
    if (checked) {
      setFieldValue('allow_trucks', true)
      setFieldValue('allow_combines', true)
      setFieldValue('allow_grain_carts', true)
    } else {
      setFieldValue('allow_trucks', false)
      setFieldValue('allow_combines', false)
      setFieldValue('allow_grain_carts', false)
    }
  }

  return (
    <div>
      <h5>
        {crewMember.first_name} {crewMember.last_name}
      </h5>
      <Formik
        enableReinitialize={false}
        initialValues={editing ? { ...crewMember } : initialValues}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          let success = false;
          if (editing && crewMember != null) {
            success = await updateCrewMember(values, crewMember.id);
          } else {
            // success = await createCrewMember(values);
          }
          if (success) {
            new AWN().success("Crew member permissions updated!", { durations: { success: 3000 }, position: "top-right" });
            useScheduleStore.setState({
              showEditForm: false,
              showScheduleEquipmentForm: false,
              scheduleToEdit: null,
              showCrewMemberForm: false,
              crewMemberToEdit: null,
            });
            setTabIndex(2);
            resetForm();
          } else {
            alert("Error submitting form");
          }
          setSubmitting(false);
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form style={{ fontSize: "25px" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" onChange={(e) => checkOrUncheckAll(e.target.checked, setFieldValue) } />
                  <label for="vehicle1"> Select/deselect all</label><br></br>
                </div>
              </div>

              <br />

              <div className="row">
                <div className="col">
                  <label>
                    <Field name="allow_trucks" label="Allow trucks" type="checkbox" />
                    &nbsp;Allow trucks
                  </label>
                  <ErrorMessage name="allow_trucks" component="div" className="formik-error" />
                </div>
                <div className="col"></div>
              </div>

              <div className="row">
                <div className="col">
                  <label>
                    <Field name="allow_combines" label="Allow combines" type="checkbox" />
                    &nbsp;Allow combines
                  </label>
                  <ErrorMessage name="allow_combines" component="div" className="formik-error" />
                </div>
                <div className="col"></div>
              </div>

              <div className="row">
                <div className="col">
                  <label>
                    <Field name="allow_grain_carts" label="Allow grain carts" type="checkbox" />
                    &nbsp;Allow grain carts
                  </label>
                  <ErrorMessage name="allow_grain_carts" component="div" className="formik-error" />
                </div>
                <div className="col"></div>
              </div>
            </div>

            <div className="action-stuck-bottom">
              <button type="submit" disabled={loading} className="btn btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
