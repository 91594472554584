import { useState } from "react";
import { Formik, Form, Field } from "formik";

import useFieldStore from "../stores/fieldStore";
import useCrewStore from "../stores/crewStore";
import useUIStore from "../stores/uiStore";

export default function CrewEquipmentform({ setShowModal }) {
  const customerFields = useFieldStore((state) => state.customerFields);
  const crewFieldsIds = useFieldStore((state) => state.crewFieldsIds);
  const setTempCrewFields = useFieldStore((state) => state.setTempCrewFields);
  const loading = useCrewStore((state) => state.loading);

  const [checkedValues, setCheckedValues] = useState(null);
  const [filter, setFilter] = useState("");

  // useEffect(() => {
  //   if (customerFields) {
  //     setCheckedValues(getInitialFieldSelection());
  //   }
  // }, [customerFields]);

  // const getInitialFieldSelection = () => {
  //   return customerFields.map((e) => {
  //     if (e.crew_id === selectedCrew) {
  //       return e.id.toString();
  //     }
  //   });
  // };

  const fieldLabel = (field) => {
    if (crewFieldsIds.includes(field.id)) {
      return `${field.name} (currently assigned to crew) (Farm: ${field.farm_name})`;
    } else {
      return field.name;
    }
  };

  const renderForm = () => {
    return (
      <div>
        <input onChange={(e) => setFilter(e.target.value)} type="text" className="filter mb-5" placeholder="Filter Fields"></input>
        <Formik
          enableReinitialize={true}
          initialValues={{ field_ids: checkedValues }}
          onSubmit={async (values) => {
            if (!(values.field_ids == null || values.field_ids.length === 0)) {
              setShowModal(false);
              useUIStore.setState({ showSecondaryRightMenu: true, showRightMenu: false });
              const updated = await setTempCrewFields(values);
            }
          }}>
          {({ isSubmitting }) => (
            <Form>
              <div role="group" aria-labelledby="checkbox-group">
                {Object.keys(customerFields).map((client) => {
                  return (
                    <>
                      <h4 className="mb-3">
                        {client} {customerFields[client].farm_count}
                      </h4>
                      <div className="container">
                        <div className="row align-stretch">
                          {customerFields[client].fields.map((field) => {
                            if (field?.name?.toLowerCase().includes(filter?.toLowerCase()) || field?.farm_name?.toLowerCase().includes(filter?.toLowerCase()) || field?.client_name?.toLowerCase().includes(filter?.toLowerCase())) {
                              return (
                                <div className="col-4 mb-3 d-flex align-stretch">
                                  <div className="check-as-button">
                                    <Field
                                      id={`field-${field.id}`}
                                      key={field.id}
                                      type="checkbox"
                                      name="field_ids"
                                      value={field.id.toString()}
                                    />
                                    <label for={`field-${field.id}`}>{fieldLabel(field)}</label>
                                  </div>
                                </div>
                              );
                            } else {
                              return "";
                            }
                          })}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="action-stuck-bottom">
                <button type="submit" disabled={loading} className="btn btn-primary">
                  View
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <div>{renderForm()}</div>;
}
