import ReactModal from "react-modal";

import useUIStore from "../stores/uiStore";
import { setReloadTimestamp } from "../lib/storage";

export default function FieldReloadModal() {
  const showFieldReloadModal = useUIStore((state) => state.showFieldReloadModal);

  const reloadPage = () => {
    setReloadTimestamp(new Date());
    window.location.reload(false);
  };

  return (
    <ReactModal
      // TODO: Set this
      ariaHideApp={false}
      isOpen={showFieldReloadModal}
      contentLabel="Field Reload Modal"
      className="field-reload-modal"
      style={{
        overlay: {
          zIndex: 99999999,
        },
        content: {
          width: "50%",
          height: "20%",
          color: "lightsteelblue",
          backgroundColor: "#2d2e31",
          backdropFilter: "none !important",
          margin: "0 auto",
          marginTop: "20%",
          minHeight: "200px",
        },
      }}>
      <br />
      <div className="text-center">
        <h4>A crew member has updated your field</h4>
      </div>
      <br />
      <div className="text-center">
        <button className="btn" onClick={reloadPage}>
          OK
        </button>
      </div>
    </ReactModal>
  );
}
