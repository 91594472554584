import { useEffect, useState } from "react";
import moment from "moment";

import useScheduleStore from "../../stores/scheduleStore";

export default function Schedule({ schedule }) {
  const fetchSchedule = useScheduleStore((state) => state.fetchSchedule);

  return (
    <tr>
      <td>{schedule.name}</td>
      <td>{schedule.notes}</td>
      <td>
        {schedule.start_time} - {schedule.end_time}
      </td>
      <td>
        <button
          className="btn"
          onClick={() => {
            useScheduleStore.setState({
              showEditForm: true,
              showScheduleEquipmentForm: false,
              showPrintSchedule: false,
              scheduleToEdit: schedule,
            });
          }}>
          <i className="fa-solid fa-pencil"></i>
        </button>
        <button
          className="btn"
          onClick={() => {
            useScheduleStore.setState({
              showEditForm: false,
              showScheduleEquipmentForm: true,
              showPrintSchedule: false,
              scheduleToEdit: schedule,
            });
          }}>
          <i className="fa-solid fa-tractor"></i>
        </button>
        <button
          className="btn"
          onClick={() => {
            fetchSchedule(schedule.id);
            useScheduleStore.setState({
              showEditForm: false,
              showScheduleEquipmentForm: false,
              showPrintSchedule: true,
              scheduleToEdit: schedule,
            });
          }}>
          <i className="fa-solid fa-print"></i>
        </button>
      </td>
    </tr>
  );
}
