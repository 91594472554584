import ReactModal from "react-modal";

import useUIStore from "../../stores/uiStore";
import SilageTruckForm from "./SilageTruckForm";

export default function SilageTruckModal() {
  const showSilageTruckModal = useUIStore((state) => state.showSilageTruckModal);
  return (
    <>
      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showSilageTruckModal}
        // onAfterOpen={
        //   () => fetchCustomerFields()
        //   /* Function that will be run after the modal has opened. */
        // }
        contentLabel="Silage Bagger"
        style={{
          overlay: {
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Silage Truck Ticket</h4>
            <button onClick={() => useUIStore.setState({ showSilageTruckModal: false })}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <div className="modal-content">
            <SilageTruckForm />
          </div>
        </div>
      </ReactModal>
    </>
  );
}
