import moment from "moment";

import useEquipmentStore from "../stores/equipmentStore";

export default function ChatMessage({ message }) {
  const equipment = useEquipmentStore((state) => state.equipment);

  const messageUser = () => {
    if (message.equipment_id === equipment.id) {
      // return "Me";
      return "";
    } else {
      return message.equipment_name || message.equipment_id;
    }
  };

  return (
    <div className="chat-message">
      <div className="message-header">
        <div className="message-user">{messageUser()}</div>
        <div className="message-date">{moment(message.created_at).fromNow(true)}</div>
      </div>
      <div className={`message-text ${message.equipment_id === equipment.id ? "text-right" : ""}`}>{message.message}</div>
    </div>
  );
}
