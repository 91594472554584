import { useEffect, useState, useContext } from "react";

import useDumpLocationStore from "../stores/dumpLocationStore";
import { MapContext } from "./MapLayer";
import DumpLocation from "./DumpLocation";
import { styleMap } from "../lib/map";

export default function DumpLocationRenderer() {
  const mapContext = useContext(MapContext);

  const dumpLocations = useDumpLocationStore((state) => state.dumpLocations);
  // const fetchDumpLocations = useDumpLocationStore((state) => state.fetchDumpLocations);

  useEffect(() => {
    if (dumpLocations && mapContext.map && mapContext.dumpLayer) {
      renderDumpLocationsOnMap();
    }
  }, [dumpLocations, mapContext.map, mapContext.dumpLayer]);

  const renderDumpLocationsOnMap = () => {
    dumpLocations.forEach(function (dl) {
      // Example with a single layer and setting property on the feature to determine color in Map.js
      // mapContext.map.data.addGeoJson(dl.geojson).forEach((feature) => {
      //   feature.setProperty("type", "dumpLocation");
      // });

      // Adding directly to dumpLayer and still using feature to set colors
      // mapContext.dumpLayer.addGeoJson(dl.geojson).forEach((feature) => {
      //   feature.setProperty("type", "dumpLocation");
      // });

      // Adding to dumpLayer but setting style layer-wide instead
      mapContext.dumpLayer.addGeoJson(dl.geojson);
    });

    mapContext.dumpLayer.setStyle({
      fillColor: "#FF0000",
      strokeWeight: 1,
      strokeColor: "#FF0000",
    });
  };

  const renderDumpLocations = () => {
    if (dumpLocations) {
      return dumpLocations.map((dl, i) => {
        return <DumpLocation key={i} dumpLocation={dl} />;
      });
    }
  };
  //
}
