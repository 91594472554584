import { useContext } from "react";
import useEquipmentStore from "../stores/equipmentStore";
import useLocationStore from "../stores/locationStore";
import { MapContext } from "./MapLayer";
import { speedDisplay } from "../lib/parsing";
import { parseLatLong } from "../lib/parsing";

export default function MyTruck() {
  const percentFull = useEquipmentStore((state) => state.percentFull);
  const myLocation = useLocationStore((state) => state.myLocation);
  const myEquipment = useEquipmentStore((state) => state.equipment);
  const lockToEquipment = useEquipmentStore((state) => state.lockToEquipment);
  const mapContext = useContext(MapContext);

  const statusColor = (percentage) => {
    if (percentage < 51) {
      return "#96E813";
    } else if (percentage >= 51 && percentage < 80) {
      return "#F08214";
    } else {
      return "#F01414";
    }
  };

  const centerAndLock = () => {
    if (lockToEquipment === myEquipment.id) {
      // If we tap on a locked equipment, unlock it
      useEquipmentStore.setState({ focusedEquipment: myEquipment.id, lockToEquipment: null });
    } else {
      // Lock to my equipment
      mapContext.map.setCenter(parseLatLong(myEquipment.last_location));
      useEquipmentStore.setState({ focusedEquipment: myEquipment.id, lockToEquipment: myEquipment.id });
    }
  };

  return (
    <div className="truck truck-mine" onClick={centerAndLock}>
      <div className="truck-card-details">
        <div className="left">
          <div className="name">
            YOU{" "}
            {lockToEquipment != null && lockToEquipment === myEquipment.id ? (
              <i className="fa-regular fa-crosshairs-simple orange">&nbsp;</i>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="right">
          <div className="speed">
            <span className="speed-number">{speedDisplay(myLocation.speed)}</span>
            <span className="speed-unit">MPH</span>
          </div>
        </div>
      </div>
      <div className="truck-progress">
        <div className="truck-progress-status" style={{ width: `${percentFull}%`, backgroundColor: statusColor(percentFull) }}></div>
      </div>
    </div>
  );
}
