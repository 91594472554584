import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "react-datepicker/dist/react-datepicker.css";

import useScheduleStore from "../../stores/scheduleStore";
import useCrewStore from "../../stores/crewStore";

export default function LocationForm({ location = null, editing = false }) {
  const createLocation = useScheduleStore((state) => state.createLocation);
  const updateLocation = useScheduleStore((state) => state.updateLocation);
  const loading = useScheduleStore((state) => state.loading);
  const crew = useCrewStore((state) => state.crew);

  const initialValues = {
    customer_id: crew.customer_id,
    name: "",
  };

  return (
    <div>
      <Formik
        enableReinitialize={false}
        initialValues={editing ? { ...location, customer_id: crew.customer_id } : initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.name || values.name === "") {
            errors.name = "Required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          let success = false;
          if (editing && location != null) {
            success = await updateLocation(values, location.id);
          } else {
            success = await createLocation(values);
          }
          if (success) {
            resetForm();
            alert(`Location ${editing ? "updated!" : "created!"}`);
            useScheduleStore.setState({ showLocationForm: false });
          } else {
            alert("Error submitting form");
          }
          setSubmitting(false);
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="city">
                    Name
                  </label>
                  <Field name="name" label="name" type="text" />
                  <ErrorMessage name="name" component="div" className="formik-error" />
                </div>
              </div>
            </div>

            <div className="action-stuck-bottom">
              <button onClick={() => useScheduleStore.setState({ showLocationForm: false })} className="btn btn-secondary mr-3">
                Cancel
              </button>
              <button type="submit" disabled={loading} className="btn btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
