import create from "zustand";
import { devtools } from "zustand/middleware";

const useLocationStore = create(
  devtools((set) => ({
    centerMapCoords: { lat: null, lng: null },
    miles: 0,
    myLocation: { lat: null, lng: null, course: null, connected: null, speed: null, distance: null, updatePosition: true },
    lastUpdateAt: null,
  })),
  { name: "LocationStore" }
);

export const processResetSpeed = async () => {
  if (useLocationStore.getState().lastUpdateAt == null || Date.now() - useLocationStore.getState().lastUpdateAt >= 10000) {
    if (useLocationStore.getState().myLocation?.speed !== 0) {
      useLocationStore.setState({ myLocation: { ...useLocationStore.getState().myLocation, speed: 0 } });
    }
  }

  setTimeout(processResetSpeed, 10000);
};

export default useLocationStore;
