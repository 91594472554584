import create from "zustand";
import { devtools } from "zustand/middleware";

import useUIStore from "./uiStore";
import client from "../lib/client";
import { silageTicketsUrl } from "../lib/endpoints";
import { setCropType, setBagName } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useSilageTicketStore = (set) => ({
  silageTickets: [],
  silageTicketQueue: [],
  loading: false,
  hasErrors: false,
  //isQueueTicket tracks if this create request is being performend on a ticket in the queue
  createSilageTicket: async (values, isQueueTicket = false) => {
    set(() => ({ loading: true }));
    try {
      setCropType(values.crop_type);
      setBagName(values.bag_name);

      if (values.load_size !== "" && values.load_size !== "other") {
        values.tare_weight = 0;
      }

      const response = await client.post(silageTicketsUrl, { silage_ticket: { ...values }, bag_name: values.bag_name });
      if (response.data.success) {
        if (isQueueTicket) {
          // console.log("created queue ticket");
          // If this is a queued ticket, remove it from the ticket queue
          set((state) => ({
            silageTicketQueue: state.silageTicketQueue.filter((ticket) => {
              return ticket.uid !== values.uid;
            }),
            loading: false,
          }));
        } else {
          // console.log("created new ticket");
          set(() => ({ loading: false }));
          useUIStore.setState({ showSilageTruckModal: false, showSilageChopperModal: false, showSilageBaggerModal: false, loading: false });
        }
      } else {
        // console.log("creation failed");
        set(() => ({ loading: false }));
        useUIStore.setState({ showSilageTruckModal: false, showSilageChopperModal: false, showSilageBaggerModal: false, loading: false });
      }
    } catch (err) {
      if (!isQueueTicket && err && err.message && err.message === "Network Error") {
        // If this is a queued ticket, do not add it back to the queue
        set((state) => ({ silageTicketQueue: [...state.silageTicketQueue, { ...values, uid: Date.now() }], loading: false }));
      }
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
});

export const processSilageTicketQueue = async () => {
  const queue = useSilageTicketStore.getState().silageTicketQueue;
  if (queue.length > 0) {
    await useSilageTicketStore.getState().createSilageTicket(queue[0], true);
  }
  setTimeout(processSilageTicketQueue, 60000);
};

useSilageTicketStore = devtools(useSilageTicketStore, { name: "SilageTicketStore" });

export default useSilageTicketStore = create(useSilageTicketStore);
