import { useEffect, useState } from "react";

import useScheduleStore from "../../stores/scheduleStore";
import CrewMember from "./CrewMember";
import CrewMemberForm from "./CrewMemberForm";

export default function CrewMemberList({ setTabIndex }) {
  const fetchCrewMembers = useScheduleStore((state) => state.fetchCrewMembers);
  const crewMembers = useScheduleStore((state) => state.crewMembers);
  const crewMemberToEdit = useScheduleStore((state) => state.crewMemberToEdit);
  const showCrewMemberForm = useScheduleStore((state) => state.showCrewMemberForm);
  const loading = useScheduleStore((state) => state.loading);

  useEffect(() => {
    fetchCrewMembers();
  }, []);

  const renderCrewMembers = () => {
    return crewMembers.map((crewMember) => <CrewMember key={crewMember.id} value={crewMember.id} crewMember={crewMember} />);
  };

  const renderForm = () => {
    if (showCrewMemberForm) {
      return <CrewMemberForm crewMember={crewMemberToEdit} editing={true} setTabIndex={setTabIndex} />;
    }
  };

  return (
    <>
      {!showCrewMemberForm ? (
        <>
          <table className="w-100 text-center" style={{ fontSize: "25px" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Permissions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderCrewMembers()}</tbody>
          </table>
        </>
      ) : (
        renderForm()
      )}
    </>
  );
}
