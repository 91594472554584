import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import App from "./App";
import useAppStore from "./stores/appStore";
import ErrorScreen from "./ErrorScreen";
import { getLastErrorTime, setLastErrorTime, setReloadTimestamp } from "./lib/storage";

export default function ErrorWrapper() {
  const logErrorToServer = useAppStore((state) => state.logErrorToServer);

  const processError = () => {
    // If the last app crash happened more than 20 seconds ago, reload the app.
    if (!!getLastErrorTime && Date.now() - getLastErrorTime() > 20000) {
      setLastErrorTime(Date.now());
      setReloadTimestamp(new Date());
      window.location.reload();
    } else {
      setLastErrorTime(Date.now());
      return <ErrorScreen />;
    }
  };

  const logError = (error, info) => {
    logErrorToServer(error, info);
  };

  return (
    <ErrorBoundary fallbackRender={processError} onError={logError}>
      <App />
    </ErrorBoundary>
  );
}
