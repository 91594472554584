import { createRef, useEffect } from "react";
import useChatStore from "../stores/chatStore";
import ChatInput from "./ChatInput";
import ChatMessageContainer from "./ChatMessageContainer";
import ChatSubmit from "./ChatSubmit";

export default function ChatContainer() {
  const scroller = createRef();

  const messages = useChatStore((state) => state.messages);

  useEffect(() => {
    if (!scroller.current) {
      return;
    }

    scroller.current.scroll(0, scroller.current.scrollHeight);
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-messages" ref={scroller}>
        <ChatMessageContainer />
      </div>
      <div className="chat-actions">
        <ChatInput />
        <ChatSubmit />
      </div>
    </div>
  );
}
