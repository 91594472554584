import React, { useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import useFieldStore from "../../stores/fieldStore";

export default function FarmFieldSelect() {
  const fetchFarmFields = useFieldStore((state) => state.fetchCustomerFarmFields);
  const farmFields = useFieldStore((state) => state.farmFields);

  useEffect(() => {
    if (window.farmFieldInit) {
      return;
    }

    window.farmFieldInit = true;

    fetchFarmFields();
  }, [window.farmFieldInit]);

  const renderFieldOptions = () => {
    return farmFields.map((farmGroup) => (
      <optgroup key={farmGroup.farm.id} label={`${farmGroup.customer_client.name} - ${farmGroup.farm.name}`}>
        {farmGroup.fields.map((field) => (
          <option key={field.id} value={field.id}>
            {field.name}
          </option>
        ))}
      </optgroup>
    ));
  };

  return (
    <div className="col">
      <label className="formik-label" htmlFor="field_id">
        Field
      </label>
      <Field name="field_id" as="select" label="field">
        <option value="">Select a field</option>
        {renderFieldOptions()}
      </Field>
      <ErrorMessage name="field_id" component="div" className="formik-error" />
    </div>
  );
}
