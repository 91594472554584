import ReactModal from "react-modal";

import useUIStore from "../../stores/uiStore";
import GasTicketForm from "./GasTicketForm";

export default function GasTicketModal() {
  const showGasTicketModal = useUIStore((state) => state.showGasTicketModal);
  return (
    <>
      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showGasTicketModal}
        // onAfterOpen={
        //   () => fetchCustomerFields()
        //   /* Function that will be run after the modal has opened. */
        // }
        contentLabel="Fuel Ticket"
        style={{
          overlay: {
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Fuel Ticket</h4>
            <button onClick={() => useUIStore.setState({ showGasTicketModal: false })}>Close <i className="fa-solid fa-times"></i></button>
          </div>
          <div className="modal-content">
            <GasTicketForm />
          </div>
        </div>
      </ReactModal>
    </>
  );
}
