import { useContext } from "react";

import { MapContext } from "../components/MapLayer";

export default function ShowAllFields() {
  const mapContext = useContext(MapContext);

  const fitFields = () => {
    const bounds = new window.google.maps.LatLngBounds();
    mapContext.fieldLayer.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    mapContext.map.fitBounds(bounds);
  };

  return <div onClick={fitFields}><i className="fa-solid fa-vector-polygon"></i> GO TO FIELD</div>;
}
