import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useDumpTicketStore from "../../stores/dumpTicketStore";
import useDumpLocationStore from "../../stores/dumpLocationStore";
import useFieldStore from "../../stores/fieldStore";
import useUIStore from "../../stores/uiStore";
import { getMileage, getLastField, getLastDumpLocation } from "../../lib/storage";
import FarmFieldSelect from "./FarmFieldSelect";
// import { dumpTicketsUrl, crewFieldsUrl } from "../lib/endpoints";

export default function DumpTicketForm() {
  const createDumpTicket = useDumpTicketStore((state) => state.createDumpTicket);
  const fetchDumpLocations = useDumpLocationStore((state) => state.fetchDumpLocations);
  const dumpLocations = useDumpLocationStore((state) => state.dumpLocations);
  const lastDumpLocation = useDumpLocationStore((state) => state.lastDumpLocation);
  const fetchCrewFields = useFieldStore((state) => state.fetchCrewFields);
  const fields = useFieldStore((state) => state.crewFields);
  const showDumpTicketForm = useUIStore((state) => state.showDumpTicketForm);

  useEffect(() => {
    // fetchDumpLocations();
    // fetchCrewFields();
  }, []);

  // if we want async logic
  // const handleSubmit = async (values, setSubmitting) => {
  //   setSubmitting(false);
  //   const x = await createDumpTicket(values);
  //   alert(x);
  // };

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(false);
    createDumpTicket(values);
  };

  const calculateNetWeight = (values, setFieldCallback) => {
    const netWeight = values.gross_weight - values.tare_weight;
    setFieldCallback("net_weight", netWeight);
  };

  const setToEmpty = (values, fieldName, setFieldCallback) => {
    if (values[fieldName] === 0) {
      setFieldCallback(fieldName, "");
    }
  };

  const setToZero = (values, fieldName, setFieldCallback) => {
    if (values[fieldName] === "") {
      setFieldCallback(fieldName, 0);
    }
  };

  const renderFieldOptions = () => {
    return fields.map((field) => (
      <option key={field.id} value={field.id}>
        {field.name}
      </option>
    ));
  };

  const renderDumpLocations = () => {
    return dumpLocations.map((dl) => (
      <option key={dl.id} value={dl.id}>
        {dl.name}
      </option>
    ));
  };

  const getFieldId = () => {
    if (getLastField() != null) {
      return getLastField();
    } else {
      try {
        return fields[0].id;
      } catch (e) {
        return "";
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          number: "",
          crop_type: "",
          field_id: getFieldId(),
          dump_location_id: getLastDumpLocation() || "",
          gross_weight: 0,
          tare_weight: 0,
          net_weight: 0,
          test_weight: 0,
          moisture: 0,
          damage: 0,
          fm: 0,
          dry_bushels: 0,
          miles: getMileage() || 0,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.number || values.number === "") {
            errors.number = "Required";
          }
          if (!values.crop_type || values.crop_type === "") {
            errors.crop_type = "Required";
          }
          if (!values.field_id || values.field_id === "") {
            errors.field_id = "Required";
          }
          if (!values.dump_location_id || values.dump_location_id === "") {
            errors.dump_location_id = "Required";
          }
          if (!values.gross_weight || values.gross_weight === "" || values.gross_weight < 5000) {
            errors.gross_weight = "Gross weight must be at least 5000";
          }
          if (!values.tare_weight || values.tare_weight === "") {
            errors.tare_weight = "Required";
          }
          if (!values.net_weight || values.net_weight === "") {
            errors.net_weight = "Required";
          }
          if (
            (!values.test_weight && values.test_weight !== 0) ||
            (values.test_weight !== 0 && (values.test_weight < 10 || values.test_weight > 80))
          ) {
            errors.test_weight = "Test weight must be between 10 and 80";
          }
          if ((!values.moisture && values.fm !== 0) || values.moisture < 5 || values.moisture > 50) {
            errors.moisture = "Moisture % must be between 5 and 50 ";
          }
          if ((!values.damage && values.fm !== 0) || values.damage === "" || values.damage > 50) {
            errors.damage = "Damage % must be less than 50";
          }
          if ((!values.fm && values.fm !== 0) || values.fm < 0 || values.fm > 100) {
            errors.fm = "FM % must be between 0 and 100";
          }
          if (values.gross_weight <= values.tare_weight) {
            errors.net_weight = "Net weight cannot be negative";
          }
          // if (!values.dry_bushels || values.dry_bushels === "") {
          //   errors.dry_bushels = "Required";
          // }
          // if (!values.miles || values.miles === "") {
          //   errors.miles = "Required";
          // }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="container">
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="number">
                    Ticket Number
                  </label>
                  <Field name="number" label="number" type="text" />
                  <ErrorMessage name="number" component="div" className="formik-error" />
                </div>
                <div className="col">
                  <label className="formik-label" htmlFor="crop_type">
                    Crop Type
                  </label>
                  <Field name="crop_type" as="select">
                    <option value="">Select a crop type</option>
                    <option value="alfalfa">Alfalfa</option>
                    <option value="barley">Barley</option>
                    <option value="brome_grass">Brome Grass</option>
                    <option value="buck_wheat">Buck Wheat</option>
                    <option value="canary_seed">Canary Seed</option>
                    <option value="canola">Canola</option>
                    <option value="corn">Corn</option>
                    <option value="earlage">Earlage</option>
                    <option value="flax">Flax</option>
                    <option value="lentil">Lentils</option>
                    <option value="linola">Linola</option>
                    <option value="manure">Manure</option>
                    <option value="millet">Millet</option>
                    <option value="mustard">Mustard</option>
                    <option value="oats">Oats</option>
                    <option value="peas">Peas</option>
                    <option value="potatoes">Potatoes</option>
                    <option value="rye">Rye</option>
                    <option value="silage">Silage</option>
                    <option value="sorghum">Sorghum</option>
                    <option value="soybeans">Soybeans</option>
                    <option value="straw">Straw</option>
                    <option value="sunflowers">Sunflowers</option>
                    <option value="triticale">Triticale</option>
                    <option value="wheat">Wheat</option>
                  </Field>
                  <ErrorMessage name="crop_type" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <FarmFieldSelect />

                <div className="col">
                  <label className="formik-label" htmlFor="dump_location_id">
                    Dump Location
                  </label>
                  <Field name="dump_location_id" label="dump_location" as="select">
                    <option value="">Select a dump location</option>
                    {renderDumpLocations()}
                  </Field>
                  <ErrorMessage name="dump_location_id" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="gross_weight">
                    Gross Weight
                  </label>
                  <Field
                    name="gross_weight"
                    label="gross_weight"
                    type="number"
                    onBlur={() => calculateNetWeight(values, setFieldValue)}
                    onClick={() => setToEmpty(values, "gross_weight", setFieldValue)}
                  />
                  <ErrorMessage name="gross_weight" component="div" className="formik-error" />
                </div>
                <div className="col">
                  <label className="formik-label" htmlFor="tare_weight">
                    Tare Weight
                  </label>
                  <Field
                    name="tare_weight"
                    label="tare_weight"
                    type="number"
                    onBlur={() => calculateNetWeight(values, setFieldValue)}
                    onClick={() => setToEmpty(values, "tare_weight", setFieldValue)}
                  />
                  <ErrorMessage name="tare_weight" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="net_weight">
                    Net Weight
                  </label>
                  <Field name="net_weight" label="net_weight" type="number" disabled={true} />
                  <ErrorMessage name="net_weight" component="div" className="formik-error" />
                </div>
                <div className="col">
                  <label className="formik-label" htmlFor="test_weight">
                    Test Weight
                  </label>
                  <Field
                    name="test_weight"
                    label="test_weight"
                    type="number"
                    onClick={() => setToEmpty(values, "test_weight", setFieldValue)}
                    onBlur={() => setToZero(values, "test_weight", setFieldValue)}
                  />
                  <ErrorMessage name="test_weight" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="moisture">
                    Moisture %
                  </label>
                  <Field
                    name="moisture"
                    label="moisture"
                    type="number"
                    onClick={() => setToEmpty(values, "moisture", setFieldValue)}
                    onBlur={() => setToZero(values, "moisture", setFieldValue)}
                  />
                  <ErrorMessage name="moisture" component="div" className="formik-error" />
                </div>
                <div className="col">
                  <label className="formik-label" htmlFor="damage">
                    Damage %
                  </label>
                  <Field
                    name="damage"
                    label="damage"
                    type="number"
                    onClick={() => setToEmpty(values, "damage", setFieldValue)}
                    onBlur={() => setToZero(values, "damage", setFieldValue)}
                  />
                  <ErrorMessage name="damage" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="fm">
                    FM %
                  </label>
                  <Field
                    name="fm"
                    label="fm"
                    type="number"
                    onClick={() => setToEmpty(values, "fm", setFieldValue)}
                    onBlur={() => setToZero(values, "fm", setFieldValue)}
                  />
                  <ErrorMessage name="fm" component="div" className="formik-error" />
                </div>
                <div className="col">
                  <label className="formik-label" htmlFor="dry_bushels">
                    Dry (Net) Bushels
                  </label>
                  <Field
                    name="dry_bushels"
                    label="dry_bushels"
                    type="number"
                    onClick={() => setToEmpty(values, "dry_bushels", setFieldValue)}
                    onBlur={() => setToZero(values, "dry_bushels", setFieldValue)}
                  />
                  <ErrorMessage name="dry_bushels" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="miles">
                    Miles
                  </label>
                  <Field
                    name="miles"
                    label="miles"
                    type="number"
                    onClick={() => setToEmpty(values, "miles", setFieldValue)}
                    onBlur={() => setToZero(values, "miles", setFieldValue)}
                  />
                  <ErrorMessage name="miles" component="div" className="formik-error" />
                </div>
              </div>
            </div>
            <div className="action-stuck-bottom">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
