import { useContext, useEffect, useState } from "react";
import moment from "moment";

import { MapContext } from "../components/MapLayer";
import { parseLatLong, speedDisplay } from "../lib/parsing";
import { truckTypes, combineTypes } from "../lib/equipmentTypes";
import useEquipmentStore from "../stores/equipmentStore";

export default function Equipment({ equipmentName, location, equipment }) {
  const mapContext = useContext(MapContext);
  const focusedEquipment = useEquipmentStore((state) => state.focusedEquipment);
  const lockToEquipment = useEquipmentStore((state) => state.lockToEquipment);
  const [ETA, setETA] = useState("");

  // Used for tracking ETA countdown
  useEffect(() => {
    const interval = setInterval(() => calculateETA(), 1000);
    return () => clearInterval(interval);
  }, [equipment]);

  const moveTo = () => {
    if (focusedEquipment == null || focusedEquipment !== equipment.id) {
      // if there is no focused equipment or the focused equipment is not the clicked one,
      // focus on this new one
      useEquipmentStore.setState({ focusedEquipment: equipment.id, lockToEquipment: null });
    } else if (focusedEquipment === equipment.id && lockToEquipment !== equipment.id) {
      // if we are focusing on this equipment already, lock to this equipment
      useEquipmentStore.setState({ lockToEquipment: equipment.id });
    } else if (lockToEquipment === equipment.id) {
      // if we tap on a locked equipment, unlock it
      useEquipmentStore.setState({ focusedEquipment: equipment.id, lockToEquipment: null });
    }

    if (location) {
      mapContext.map.setCenter(parseLatLong(location));
    } else {
      alert("No location data for selected equipment");
    }
  };

  const calculateETA = () => {
    const eta_seconds = moment(equipment.eta_timestamp).diff(moment(), "seconds");
    if (!!eta_seconds && eta_seconds >= 0) {
      setETA(`ETA ${Math.ceil(eta_seconds / 60)}m ${eta_seconds % 60}s`);
    } else {
      setETA("");
    }
  };

  const equipmentStatus = () => {
    if (truckTypes.includes(equipment.equipment_type)) {
      switch (equipment.location_status) {
        case "RF":
          if (equipment.last_value != null && equipment.last_value !== 0) {
            return (
              <>
                D <i className="fa-solid fa-caret-right"></i> F {ETA}
              </>
            );
          } else {
            return (
              <>
                D <i className="fa-solid fa-caret-right"></i> F
              </>
            );
          }
        case "RD":
          return (
            <>
              F <i className="fa-solid fa-caret-right"></i> D
            </>
          );
        case "D":
          return "@Dump";
        case "F":
          return "@Field";
        default:
          return equipment.location_status;
      }
    }
  };

  const statusColor = (percentage) => {
    if (percentage < 51) {
      return "#96E813";
    } else if (percentage >= 51 && percentage < 80) {
      return "#F08214";
    } else {
      return "#F01414";
    }
  };

  const evalEquipmentStatusColor = (equipmentType, locationStatus, fillPercent) => {
    if (truckTypes.includes(equipmentType)) {
      switch (locationStatus) {
        case "D":
          return { width: `${fillPercent}%`, maxWidth: "100%", backgroundColor: statusColor(fillPercent) };
        case "RF":
          return { width: `100%`, maxWidth: "100%", backgroundColor: "yellow" };
        case "F":
          return {
            width: `${fillPercent == null || fillPercent === 0 || fillPercent === 1 ? 100 : fillPercent}%`,
            maxWidth: "100%",
            backgroundColor: statusColor(fillPercent),
          };
        case "RD":
          return { width: `${fillPercent}%`, maxWidth: "100%", backgroundColor: statusColor(fillPercent) };
        default:
          return { width: `${fillPercent}%`, maxWidth: "100%", backgroundColor: statusColor(fillPercent) };
      }
    } else {
      return { width: `0%`, maxWidth: "100%", backgroundColor: statusColor(fillPercent) };
    }
  };

  const getIconForEquipment = (equipmentType) => {
    switch (equipmentType) {
      case "semi":
        return "fa-solid fa-truck-container";
      case "dirt_wagon":
        return "fa-solid fa-truck-container";
      case "straight_truck":
        return "fa-solid fa-truck";
      case "tractor_wagon":
        return "fa-solid fa-tractor";
      case "chopper":
        return "fak fa-combine";
      case "mower":
        return "fa-solid fa-sickle";
      case "merger":
        return "fa-solid fa-merge";
      case "push_tractor":
        return "fa-solid fa-cart-flatbed-boxes";
      default:
        return "test";
    }
  };

  return (
    <div className="equipment" onClick={moveTo}>
      {equipment.last_speed && (
        <>
          <div className="equipment-data">
            <div className="equipment-icon">
              <i className={getIconForEquipment(equipment.equipment_type)}></i>
            </div>
            <div className="equipment-detail">
              <div className="name">
                <span>
                  {lockToEquipment === equipment.id ? <i className="fa-regular fa-crosshairs-simple orange">&nbsp;</i> : ""}
                  {/* 4/21/23 Wanted equipment type removed from label */}
                  {/* {equipmentName} {equipment.equipment_type}{" "} */}
                  {equipmentName}{" "}
                </span>
              </div>
              <div className="status">{equipmentStatus()}</div>
            </div>
            <div className="equipment-status">
              <span className="speed">{speedDisplay(equipment.last_speed)}</span>
              <br />
              <span className="speed-label">MPH</span>
            </div>
          </div>
          <div className="equipment-meter">
            <div
              className="equipment-meter-line"
              // style={{ width: `${equipment.fill_percent}%`, backgroundColor: statusColor(equipment.fill_percent) }}
              style={evalEquipmentStatusColor(equipment.equipment_type, equipment.location_status, equipment.fill_percent)}></div>
          </div>
        </>
      )}
      {!equipment.last_speed && (
        <>
          <div className="equipment-data equipment-inactive">
            <div className="equipment-detail">
              <div className="name">
                <span>
                  {/* 4/21/23 Wanted equipment type removed from label */}
                  {/* {equipmentName} {equipment.equipment_type} */}
                  {equipmentName}
                </span>
              </div>
            </div>
            <div className="equipment-status">
              <div className="inactive">INACTIVE</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
