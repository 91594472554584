import useChatStore from "../stores/chatStore";

export default function ChatInput() {
  const setNewMessage = useChatStore((state) => state.setNewMessage);
  const newMessage = useChatStore((state) => state.newMessage);

  const handleChange = (e) => {
    setNewMessage(e.target.value);
  };

  return (
    <div className="chat-input">
      <textarea cols="30" rows="3" onChange={handleChange} placeholder="Send a message" value={newMessage || ""}></textarea>
    </div>
  );
}
