import useUIStore from "../stores/uiStore";

export default function FooterBar() {
  return (
    <div className="footer-bar">
      <div className="footer-item" onClick={() => useUIStore.setState({ showEditCrewModal: true })}>
        <i className="fa-solid fa-user-group"></i> Crew
      </div>
      <div className="footer-item" onClick={() => useUIStore.setState({ showScheduleModal: true })}>
        <i className="fa-sharp fa-solid fa-calendar-days"></i> Scheduling
      </div>
      <div
        className="footer-item"
        onClick={() =>
          useUIStore.setState({
            showTicketModal: true,
          })
        }>
        <i className="fa-solid fa-receipt"></i> Tickets
      </div>
      <div className="footer-item" onClick={() => useUIStore.setState({ showProfileModal: true })}>
        <i className="fa-solid fa-circle-user"></i> Profile
      </div>
    </div>
  );
}
