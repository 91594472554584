import { useEffect, useContext } from "react";

import useDumpLocationStore from "../stores/dumpLocationStore";
import useUIStore from "../stores/uiStore";
import { MapContext } from "./MapLayer";
import DumpLocation from "./DumpLocation";

export default function DumpLocationList() {
  const mapContext = useContext(MapContext);
  const dumpLocations = useDumpLocationStore((state) => state.dumpLocations);

  const centerMap = (dumpLocation) => {
    // eslint-disable-next-line no-undef
    const centerCoords = turf.center(dumpLocation.geojson);
    const latLng = { lat: centerCoords.geometry.coordinates[1], lng: centerCoords.geometry.coordinates[0] };

    mapContext.map.setZoom(15);
    mapContext.map.panTo(latLng);

    useUIStore.setState({ showProfileModal: false });
  };

  const renderDumpLocationsList = () => {
    if (dumpLocations.length > 0) {
      return (
        <ol>
          {dumpLocations.map((dl) => (
            <li key={dl.id} onClick={() => centerMap(dl)} style={{ paddingBottom: "15px" }}>
              {dl.name}
            </li>
          ))}
        </ol>
      );
    }
  };

  return renderDumpLocationsList();
  //
}
