import create from "zustand";
import { devtools } from "zustand/middleware";

import client from "../lib/client";
import useUIStore from "./uiStore";
import { customerMapPinsUrl, customerMapPinUrl } from "../lib/endpoints";

// let instead of const because we want to be able to use redux devtools
let useCustomerMapPinStore = (set) => ({
  customerMapPins: [],
  allowCreatePin: false,
  newPinCoords: {},
  loading: false,
  hasErrors: false,
  fetchCustomerMapPins: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(customerMapPinsUrl);
      set((state) => ({ customerMapPins: response.data.customer_map_pins, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  createPin: async (values) => {
    try {
      const response = await client.post(customerMapPinsUrl, { customer_map_pin: { ...values } });
      if (response.data.success) {
        set((state) => ({
          customerMapPins: [...state.customerMapPins, response.data.customer_map_pin],
          newPinCoords: {},
          allowCreatePin: false,
          loading: false,
        }));
        useUIStore.setState({ showPinModal: false, showSecondaryRightMenu: false, showRightMenu: false });
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  deletePin: async (pinId) => {
    try {
      const response = await client.delete(customerMapPinUrl(pinId));
      if (response.data.success) {
        set((state) => ({ customerMapPins: state.customerMapPins.filter((pin) => parseInt(pin.id) !== parseInt(pinId)), loading: false }));
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
});

useCustomerMapPinStore = devtools(useCustomerMapPinStore, { name: "CustomerMapPinStore" });

export default useCustomerMapPinStore = create(useCustomerMapPinStore);
