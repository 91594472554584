import { useEffect, useState, useContext } from "react";
import moment from "moment";

import { parseLatLong } from "../lib/parsing";
import { MapContext } from "../components/MapLayer";
import useFieldStore from "../stores/fieldStore";

// let icons = {};

export default function FieldEntryPointMarkers() {
  const mapContext = useContext(MapContext);
  const fieldEntryPoints = useFieldStore((state) => state.fieldEntryPoints);

  const [markerList, setMarkerList] = useState({});
  const [iconElements, setIconElements] = useState({});
  const [initializedMarkers, setInitializedMarkers] = useState(false);

  // Draws markers onto the map and also handles deletion
  useEffect(() => {
    if (mapContext.map && fieldEntryPoints != null && fieldEntryPoints.length > 0) {
      let markers = {};
      let icons = {};
      fieldEntryPoints.forEach(function (fieldEntryPoint) {
        if (fieldEntryPoint.location) {
          icons[fieldEntryPoint.id] = document.createElement("div");

          icons[fieldEntryPoint.id].className = "field-entry-icon";

          const marker = new window.google.maps.marker.AdvancedMarkerView({
            map: mapContext.map,
            position: parseLatLong(fieldEntryPoint.location),
            content: icons[fieldEntryPoint.id],
          });

          marker.addListener("tap", () => {
            marker.infowindow.open(mapContext.map, marker);
          });
          marker.addListener("click", () => {
            marker.infowindow.open(mapContext.map, marker);
          });

          marker.infowindow = new window.google.maps.InfoWindow({
            content: `<div style="color: #000000;"><div class="pin-note">Field entered: <br>${fieldEntryPoint.name}<br>${moment(
              fieldEntryPoint.sent_at
            ).format("M/D/y h:mma")}</div></div>`,
            pixelOffset: new window.google.maps.Size(0, 30),
            // TODO: Dynamic info window
            // content: `<div style="color: #000000;"><div class="pin-note" id="equipment-marker-${equipment.id}">${timer}</div></div>`,
          });

          // TODO: Dynamic info window
          // If equipment has the timer timestamp not null
          // marker.infowindow.open(mapContext.map, marker);

          // icons[equipment.id] = equipmentIcon;
          markers[fieldEntryPoint.id] = marker;
        }
      });

      setMarkerList(markers);
      setIconElements(icons);
      setInitializedMarkers(true);
    }
  }, [mapContext.map, fieldEntryPoints]);
}
