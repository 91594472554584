import { useEffect, useState } from "react";

import { noIntercept } from "../lib/client";
import useLocationStore from "../stores/locationStore";
import useWeatherStore from "../stores/weatherStore";

export default function WeatherInfo() {
  const haveFetchedWeather = useWeatherStore((state) => state.haveFetchedWeather);
  const today = useWeatherStore((state) => state.today);
  const tonight = useWeatherStore((state) => state.tonight);
  const fetchWeather = useWeatherStore((state) => state.fetchWeather);
  const deviceLocation = useLocationStore((state) => state.centerMapCoords);

  useEffect(() => {
    if (!haveFetchedWeather) {
      if (deviceLocation.lat != null && deviceLocation.lng != null) {
        fetchWeather(deviceLocation);
      }
    }
  }, []);

  const openExternalWeather = () => {
    window.open(`https://forecast.weather.gov/MapClick.php?lat=${deviceLocation.lat}&lon=${deviceLocation.lng}`, "_blank");
  };

  return (
    <>
      <div className="profile-card">
        <div className="profile-small-label">TODAY'S FORECAST</div>
        <div className="profile-info">{today}</div>
        <br />
        <div className="profile-small-label">TONIGHT'S FORECAST</div>
        <div className="profile-info">{tonight}</div>
        <br />
        <div className="forecast-details" onClick={openExternalWeather}>
          FORECAST DETAILS &nbsp;<i className="fa-solid fa-up-right-from-square"></i>
        </div>
      </div>
    </>
  );
}
