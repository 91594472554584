import create from "zustand";
import { devtools } from "zustand/middleware";

import useUIStore from "./uiStore";
import client from "../lib/client";
import { dumpTicketsUrl } from "../lib/endpoints";

// let instead of const because we want to be able to use redux devtools
let useDumpTicketStore = (set) => ({
  dumpTickets: [],
  dumpTicketQueue: [],
  loading: false,
  hasErrors: false,
  //isQueueTicket tracks if this create request is being performend on a ticket in the queue
  createDumpTicket: async (values, isQueueTicket = false) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(dumpTicketsUrl, { dump_ticket: { ...values } });
      if (response.data.success) {
        if (isQueueTicket) {
          console.log("created queue ticket");
          // If this is a queued ticket, remove it from the ticket queue
          set((state) => ({
            dumpTicketQueue: state.dumpTicketQueue.filter((ticket) => {
              return ticket.uid !== values.uid;
            }),
            loading: false,
          }));
        } else {
          console.log("created new ticket");
          useUIStore.setState({ showDumpTicketModal: false });
        }
      } else {
        console.log("creation failed");
        useUIStore.setState({ showDumpTicketModal: false });
      }
    } catch (err) {
      if (!isQueueTicket && err && err.message && err.message === "Network Error") {
        // If this is a queued ticket, do not add it back to the queue
        set((state) => ({ dumpTicketQueue: [...state.dumpTicketQueue, { ...values, uid: Date.now() }], loading: false }));
      }
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
});

export const processDumpTicketQueue = async () => {
  const queue = useDumpTicketStore.getState().dumpTicketQueue;
  if (queue.length > 0) {
    await useDumpTicketStore.getState().createDumpTicket(queue[0], true);
  }
  setTimeout(processDumpTicketQueue, 60000);
};

useDumpTicketStore = devtools(useDumpTicketStore, { name: "DumpTicketStore" });

export default useDumpTicketStore = create(useDumpTicketStore);
