import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useScheduleStore from "../../stores/scheduleStore";

export default function VerifyPINForm() {
  const verifySchedulePIN = useScheduleStore((state) => state.verifySchedulePIN);

  const [showError, setShowError] = useState(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ pin: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.pin || values.pin === "") {
          errors.device_name = "Required";
        }
        return errors;
      }}
      onSubmit={async (values) => {
        const verified = await verifySchedulePIN(values);
        if (!verified) {
          setShowError(true);
        }
      }}>
      {({ isSubmitting }) => (
        <>
          <h3 className="text-center orange">Enter PIN</h3>
          <p className="text-center orange">To access the scheduling tool, please enter your assigned PIN.</p>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="pin">
                    PIN
                  </label>
                  <Field name="pin" label="pin" type="text" />
                  <ErrorMessage name="pin" component="div" className="formik-error" />

                  {showError && (
                    <div>
                      <p className="red">Could not verify this PIN</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="action-stuck-bottom">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                Verify PIN
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
