import { useState } from "react";
import moment from "moment";
import ReactModal from "react-modal";

import useScheduleStore from "../../stores/scheduleStore";
import AssignmentCard from "./AssignmentCard";

export default function PrintScheduleModal() {
  const showPrintSchedule = useScheduleStore((state) => state.showPrintSchedule);
  const schedule = useScheduleStore((state) => state.scheduleToEdit);
  const currentEquipmentCrew = useScheduleStore((state) => state.currentEquipmentCrew);
  const locationOptions = useScheduleStore((state) => state.locationOptions);
  const [selectedLocation, setSelectedLocation] = useState("");

  const renderAssignments = () => {
    if (!!!selectedLocation || selectedLocation === "all") {
      return currentEquipmentCrew.map((assignment, index) => {
        return <AssignmentCard key={index} assignment={assignment} />;
      });
    } else {
      return currentEquipmentCrew.map((assignment, index) => {
        if (assignment.location_id.toString() === selectedLocation) {
          return <AssignmentCard key={index} assignment={assignment} />;
        }
      });
    }
  };

  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={showPrintSchedule}
        contentLabel="Print Shedule"
        style={{
          overlay: {
            // backgroundColor: "papayawhip",
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">
              {schedule?.location} <br /> {moment().format("L")} <br />
              {schedule?.start_time} - {schedule?.end_time} <br />
              {schedule?.notes}
            </h4>
            <button onClick={() => useScheduleStore.setState({ showPrintSchedule: !showPrintSchedule })}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <select className="float-left" style={{ width: "15em" }} onChange={(e) => setSelectedLocation(e.target.value)}>
            <option value="all">All locations</option>
            {locationOptions.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
          <br />
          <div className="modal-content">{renderAssignments()}</div>
        </div>
      </ReactModal>
    </>
  );
}
