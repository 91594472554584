import { useContext } from "react";
import { MapContext } from "./MapLayer";
import useUIStore from "../stores/uiStore";

export default function MapControls() {
  const mapContext = useContext(MapContext);
  const map = mapContext.map;

  const showMapControls = useUIStore((state) => state.showMapControls);

  const panControl = (direction) => {
    const offsetHeight = map.getDiv().offsetHeight;
    const offsetWidth = map.getDiv().offsetWidth;

    switch (direction) {
      case "UP":
        map.panBy(0, Math.round(offsetHeight / 10) * -1);
        break;
      case "LEFT":
        map.panBy(Math.round(offsetWidth / 10) * -1, 0);
        break;
      case "RIGHT":
        map.panBy(Math.round(offsetWidth / 10), 0);
        break;
      case "DOWN":
        map.panBy(0, Math.round(offsetHeight / 10));
        break;
      default:
        break;
    }
  };

  if (showMapControls) {
    return (
      <div className="map-controls">
        <div className="zoom-controls">
          <button type="button" className="zoom-control" onClick={() => map.setZoom(map.zoom - 1)}>
            <span aria-label="zoom out">
              <i className="fa-solid fa-minus"></i>
            </span>
          </button>

          <button type="button" className="zoom-control" onClick={() => map.setZoom(map.zoom + 1)}>
            <span aria-label="zoom in">
              <i className="fa-solid fa-plus"></i>
            </span>
          </button>
        </div>

        <div className="pan-controls">
          <button type="button" className="pan-control up" onClick={() => panControl("UP")}>
            <span aria-label="pan up">
              <i className="fa-solid fa-chevron-up"></i>
            </span>
          </button>

          <button type="button" className="pan-control left" onClick={() => panControl("LEFT")}>
            <span aria-label="pan left">
              <i className="fa-solid fa-chevron-left"></i>
            </span>
          </button>

          <button type="button" className="pan-control right" onClick={() => panControl("RIGHT")}>
            <span aria-label="pan right">
              <i className="fa-solid fa-chevron-right"></i>
            </span>
          </button>

          <button type="button" className="pan-control down" onClick={() => panControl("DOWN")}>
            <span aria-label="pan down">
              <i className="fa-solid fa-chevron-down"></i>
            </span>
          </button>
        </div>
      </div>
    );
  }
}
