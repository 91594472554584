import { useEffect, useState, useContext } from "react";
import moment from "moment";

import { parseLatLong } from "../lib/parsing";
import { MapContext } from "../components/MapLayer";
import { truckTypes, combineTypes } from "../lib/equipmentTypes";
import useWeatherStore from "../stores/weatherStore";

// let icons = {};

export default function WeatherMarkers() {
  const mapContext = useContext(MapContext);
  const weatherStatuses = useWeatherStore((state) => state.weatherStatuses);

  const [markerList, setMarkerList] = useState({});
  const [iconElements, setIconElements] = useState({});
  const [initializedMarkers, setInitializedMarkers] = useState(false);

  const assignDirection = (direction) => {
    if (isNaN(direction)) {
      return "north";
    } else if (direction >= 0 && direction < 22.5) {
      return "north";
    } else if (direction >= 22.5 && direction < 45) {
      return "north-north-east";
    } else if (direction >= 45 && direction < 67.5) {
      return "north-east";
    } else if (direction >= 67.5 && direction < 90) {
      return "east-north-east";
    } else if (direction >= 90 && direction < 112.5) {
      return "east";
    } else if (direction >= 112.5 && direction < 135) {
      return "east-south-east";
    } else if (direction >= 135 && direction < 157.5) {
      return "south-east";
    } else if (direction >= 157.5 && direction < 180) {
      return "south-south-east";
    } else if (direction >= 180 && direction < 202.5) {
      return "south";
    } else if (direction >= 202.5 && direction < 225) {
      return "south-south-west";
    } else if (direction >= 225 && direction < 247.5) {
      return "south-west";
    } else if (direction >= 247.5 && direction < 270) {
      return "west-south-west";
    } else if (direction >= 270 && direction < 292.5) {
      return "west";
    } else if (direction >= 292.5 && direction < 315) {
      return "west-north-west";
    } else if (direction >= 315 && direction < 337.5) {
      return "north-west";
    } else if (direction >= 337.5 && direction < 360) {
      return "north-north-west";
    } else {
      return "north";
    }
  };

  // Draws markers onto the map and also handles deletion
  useEffect(() => {
    if (mapContext.map && weatherStatuses != null && weatherStatuses.length > 0) {
      let markers = {};
      let icons = {};
      weatherStatuses.forEach(function (weatherStatus) {
        if (weatherStatus.location) {
          icons[weatherStatus.id] = document.createElement("div");

          icons[weatherStatus.id].className = `circle pin-white weather-icon ${assignDirection(parseFloat(weatherStatus.course))}`;

          const marker = new window.google.maps.marker.AdvancedMarkerView({
            map: mapContext.map,
            position: parseLatLong(weatherStatus.location),
            content: icons[weatherStatus.id],
          });

          marker.addListener("tap", () => {
            marker.infowindow.open(mapContext.map, marker);
          });
          marker.addListener("click", () => {
            marker.infowindow.open(mapContext.map, marker);
          });

          marker.infowindow = new window.google.maps.InfoWindow({
            content: `<div style="color: #000000;"><div class="pin-note">${weatherStatus.summary}<br />${moment(
              weatherStatus.sent_at
            ).format("M/D h:mma")}</div></div>`,
            pixelOffset: new window.google.maps.Size(0, 30),
            // TODO: Dynamic info window
            // content: `<div style="color: #000000;"><div class="pin-note" id="equipment-marker-${equipment.id}">${timer}</div></div>`,
          });

          // TODO: Dynamic info window
          // If equipment has the timer timestamp not null
          // marker.infowindow.open(mapContext.map, marker);

          // icons[equipment.id] = equipmentIcon;
          markers[weatherStatus.id] = marker;
        }
      });

      setMarkerList(markers);
      setIconElements(icons);
      setInitializedMarkers(true);
    }
  }, [mapContext.map, weatherStatuses]);
}
