/**
 * Look up Google Maps geolocation data based on lat/lng in locationStore.
 *
 * This will set the city/state component state values based on the 'locality' response.
 */
export function geoLookup(lat, lng, callback) {
  const geocoder = new window.google.maps.Geocoder();

  const latlng = {
    lat: lat,
    lng: lng,
  };

  geocoder.geocode({ location: latlng }, (results, status) => {
    console.log("GEOCODE RESULTS: ", [status, results]);

    // check for bad responses
    if (status !== "OK") {
      console.log("Could not parse geolocation: ", status);
      return;
    }

    try {
      // use response 'locality' item
      const locality = results.filter((r) => {
        return r.types.includes("locality");
      })[0];

      console.log("Locality: ", locality);

      // parse city
      const city = locality.address_components.filter((a) => {
        return a.types.includes("locality");
      })[0]?.long_name;

      console.log("City: ", city);

      // parse state
      const state = locality.address_components.filter((a) => {
        return a.types.includes("administrative_area_level_1");
      })[0]?.short_name;

      console.log("State: ", state);

      // fire callback with parsed data
      // NOTE if other places of the cite need more than city/state, just add it here
      // or expand how this works
      callback({ city: city, state: state });

      // setCity(city);
      // setState(state);
    } catch (e) {
      console.log("Error parsing geolocation response: ", e);
    }
  });
}

export default geoLookup;
