export default null;

/**
 * Style a map object according to it's property.type field.
 */
const fillColor = { field: "white", dumpLocation: "#FF0000" };
const strokeColor = { field: "white", dumpLocation: "#FF0000" };

export function styleMap(map) {
  // https://developers.google.com/maps/documentation/javascript/datalayer#declarative_style_rules
  map.data.setStyle((feature) => {
    return {
      fillColor: fillColor[`${feature.getProperty("type")}`],
      strokeWeight: 1,
      strokeColor: strokeColor[`${feature.getProperty("type")}`],
      clickable: false,
    };
  });
}

/**
 * Center map on lat/lng
 */
export function centerMap(mapContext, lat, lng) {
  try {
    if (!lat || !lng) {
      console.log("No lat or lng found, skipping center.");
      return;
    }

    const latLng = { lat: lat, lng: lng };

    mapContext.map.setZoom(15);
    mapContext.map.panTo(latLng);
  } catch (e) {
    console.log("Error centering map: ", e);
  }
}

// This function converts feet to pixels based on the zoom level
export function feetToPixels(feet, zoom) {
  const scale = Math.pow(2, zoom);
  const metersPerPixel = 156543.03392 / scale;
  const feetPerPixel = metersPerPixel * 3.28084;
  return feet / feetPerPixel;
}
