import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useSilageTicketStore from "../../stores/silageTicketStore";
import useEquipmentStore from "../../stores/equipmentStore";
import useFieldStore from "../../stores/fieldStore";
import { getCropType, getBagName, getLastField } from "../../lib/storage";
import FarmFieldSelect from "./FarmFieldSelect";

export default function SilageBaggerForm() {
  const fetchCrewFields = useFieldStore((state) => state.fetchCrewFields);
  const fields = useFieldStore((state) => state.crewFields);
  const fetchCrewTrucks = useEquipmentStore((state) => state.fetchCrewTrucks);
  const trucks = useEquipmentStore((state) => state.crewTrucks);
  const createSilageTicket = useSilageTicketStore((state) => state.createSilageTicket);
  const loading = useSilageTicketStore((state) => state.loading);

  useEffect(() => {
    // fetchCrewFields();
    // fetchCrewTrucks();
  }, []);

  // if we want async logic
  // const handleSubmit = async (values, setSubmitting) => {
  //   setSubmitting(false);
  //   const x = await createSilageTicket(values);
  //   alert(x);
  // };

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(false);
    createSilageTicket(values);
  };

  const renderFieldOptions = () => {
    const date = new Date().valueOf();
    return fields.map((field) => (
      <option key={`${field.id}${date}`} value={field.id}>
        {field.name}
      </option>
    ));
  };

  const renderTrucks = (values) => {
    const date = new Date().valueOf();
    return trucks.map((truck) => {
      return (
        <div className="row">
          <div className="col">
            <div className="check-as-button">
              <Field
                key={`${truck.id}-${date}`}
                type="radio"
                name="equipment_id"
                id={`equipment_${truck.id}`}
                value={truck.id}
                checked={values.equipment_id === truck.id.toString()}
              />
              <label className="formik-label" htmlFor={`equipment_${truck.id}`} key={truck.id}>
                {truck.name}
              </label>
            </div>
          </div>
        </div>
      );
    });
  };

  const getFieldId = () => {
    if (getLastField() != null) {
      return getLastField();
    } else {
      try {
        return fields[0].id;
      } catch (e) {
        return "";
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          crop_type: getCropType() || "",
          field_id: getFieldId(),
          bag_name: getBagName() || "",
          equipment_id: "",
          load_size: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.crop_type || values.crop_type === "") {
            errors.crop_type = "Required";
          }
          if (!values.field_id || values.field_id === "") {
            errors.field_id = "Required";
          }
          if (!values.bag_name || values.bag_name === "") {
            errors.bag_name = "Required";
          }
          if (!values.equipment_id || values.equipment_id === "") {
            errors.equipment_id = "Required";
          }
          if (!values.load_size || values.load_size === "") {
            errors.load_size = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="container">
              <div className="row">
                <FarmFieldSelect />
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="bag_name">
                    Bag Name
                  </label>
                  <Field name="bag_name" label="bag_name" type="text" />
                  <ErrorMessage name="bag_name" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="crop_type">
                    Crop Type
                  </label>
                  <Field name="crop_type" as="select">
                    <option value="">Select a crop type</option>
                    <option value="alfalfa">Alfalfa</option>
                    <option value="barley">Barley</option>
                    <option value="brome_grass">Brome Grass</option>
                    <option value="buck_wheat">Buck Wheat</option>
                    <option value="canary_seed">Canary Seed</option>
                    <option value="canola">Canola</option>
                    <option value="corn">Corn</option>
                    <option value="earlage">Earlage</option>
                    <option value="flax">Flax</option>
                    <option value="lentil">Lentils</option>
                    <option value="linola">Linola</option>
                    <option value="manure">Manure</option>
                    <option value="millet">Millet</option>
                    <option value="mustard">Mustard</option>
                    <option value="oats">Oats</option>
                    <option value="peas">Peas</option>
                    <option value="potatoes">Potatoes</option>
                    <option value="rye">Rye</option>
                    <option value="silage">Silage</option>
                    <option value="sorghum">Sorghum</option>
                    <option value="soybeans">Soybeans</option>
                    <option value="straw">Straw</option>
                    <option value="sunflowers">Sunflowers</option>
                    <option value="triticale">Triticale</option>
                    <option value="wheat">Wheat</option>
                  </Field>
                  <ErrorMessage name="crop_type" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5 id="truck-group">Truck</h5>
                  <div role="group" aria-labelledby="truck-group">
                    {renderTrucks(values, setFieldValue)}
                  </div>
                </div>
                <div className="col">
                  <h5 id="load-size-group">Load Size</h5>
                  <div role="group" aria-labelledby="load-size-group">
                    <div className="row">
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" id="load_size_full" value="100" />
                          <label className="formik-label" htmlFor="load_size_full">
                            Full
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" id="load_size_75" value="75" />
                          <label className="formik-label" htmlFor="load_size_75">
                            3/4
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" id="load_size_50" value="50" />
                          <label className="formik-label" htmlFor="load_size_50">
                            1/2
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" value="25" id="load_size_25" />
                          <label className="formik-label" htmlFor="load_size_25">
                            1/4
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-stuck-bottom">
              <button type="submit" disabled={loading} className="btn btn-primary">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
