import create from "zustand";
import { devtools } from "zustand/middleware";

import client from "../lib/client";
import {
  verifyPINUrl,
  schedulesUrl,
  scheduleUrl,
  specificCrewFieldsUrl,
  equipmentAndCrewMembersUrl,
  updateEquipmentCrewUrl,
  crewMembersUrl,
  crewMemberUrl,
  locationsUrl,
  locationUrl,
  confirmScheduleEquipmentUrl,
} from "../lib/endpoints";
import useEquipmentStore from "./equipmentStore";

let useScheduleStore = (set) => ({
  verifyPIN: true,
  schedules: [],
  scheduleToEdit: null,
  fieldOptions: [],
  equipmentOptions: [],
  equipmentOptionsIds: [],
  crewMemberOptions: [],
  locationOptions: [],
  currentEquipmentCrew: [],
  currentlyAssignedEquipmentIds: [],
  crewMembers: [],
  locations: [],
  showEditForm: false,
  showScheduleEquipmentForm: false,
  showCrewMemberForm: false,
  crewMemberToEdit: null,
  showLocationForm: false,
  locationToEdit: null,
  showPrintSchedule: false,
  loading: false,
  hasErrors: false,

  verifySchedulePIN: async (values) => {
    try {
      const response = await client.post(verifyPINUrl, { ...values, customer_id: useEquipmentStore.getState().equipment.customer_id });
      if (response.data.success) {
        set(() => ({ verifyPIN: false }));
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  },
  fetchSchedules: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(schedulesUrl);
      if (response.data.success) {
        set((state) => ({ schedules: response.data.schedules, loading: false }));
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  fetchSchedule: async (id) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(scheduleUrl(id));
      if (response.data.success) {
        set((state) => ({
          currentEquipmentCrew: response.data.schedule_equipment,
          locationOptions: response.data.locations,
          loading: false,
        }));
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  fetchCrewMembers: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(crewMembersUrl);
      if (response.data.success) {
        set((state) => ({ crewMembers: response.data.crew_members, loading: false }));
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  fetchLocations: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(locationsUrl);
      if (response.data.success) {
        set((state) => ({ locations: response.data.locations, loading: false }));
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  fetchFields: async (crewId) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(specificCrewFieldsUrl(crewId));
      if (response.data.success) {
        set((state) => ({ fieldOptions: response.data.fields, loading: false }));
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  fetchEquipmentAndCrewMemberOptions: async (scheduleId) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(equipmentAndCrewMembersUrl(scheduleId));
      if (response.data.success) {
        set((state) => ({
          crewMemberOptions: response.data.crew_members,
          equipmentOptions: response.data.equipment,
          locationOptions: response.data.locations,
          currentEquipmentCrew: response.data.current_crew_assignments,
          equipmentOptionsIds: response.data.equipment_options_ids,
          currentlyAssignedEquipmentIds: response.data.currently_assigned_equipment_ids,
          loading: false,
        }));
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  createSchedule: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(schedulesUrl, { schedule: { ...values } });
      if (response.data.success) {
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  updateSchedule: async (values, id) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(scheduleUrl(id), { schedule: { ...values } });

      if (response.data.success) {
        const updatedSchedules = useScheduleStore.getState().schedules.map((schedule) => {
          if (schedule.id === id) {
            return {
              ...schedule,
              ...response.data.schedule,
            };
          }
          return schedule;
        });

        set(() => ({ schedules: updatedSchedules, loading: false }));
        return true;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  updateEquipmentCrew: async (values, id) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(updateEquipmentCrewUrl(id), {
        schedule: { schedule_equipment_attributes: values.schedule_equipment },
      });
      return true;
      // if (response.data.success) {
      //   const updatedSchedules = useScheduleStore.getState().schedules.map((schedule) => {
      //     if (schedule.id === id) {
      //       return {
      //         ...schedule,
      //         ...response.data.schedule,
      //       };
      //     }
      //     return schedule;
      //   });

      //   set(() => ({ schedules: updatedSchedules }));
      //   return true;
      // }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  updateCrewMember: async (values, id) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(crewMemberUrl(id), { crew_member: { ...values } });

      if (response.data.success) {
        const updatedCrewMembers = useScheduleStore.getState().crewMembers.map((crewMember) => {
          if (crewMember.id === id) {
            return {
              ...crewMember,
              ...response.data.crew_member,
            };
          }
          return crewMember;
        });

        set(() => ({ crewMembers: updatedCrewMembers, loading: false }));
        return true;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  updateLocation: async (values, id) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(locationUrl(id), { location: { ...values } });

      if (response.data.success) {
        const updatedLocations = useScheduleStore.getState().locations.map((location) => {
          if (location.id === id) {
            return {
              ...location,
              ...response.data.location,
            };
          }
          return location;
        });

        set(() => ({ locations: updatedLocations, loading: false }));
        return true;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  confirmScheduleEquipment: async (id) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(confirmScheduleEquipmentUrl, { schedule_equipment_id: id });
      if (response.data.success) {
        const updatedScheduleEquipment = useScheduleStore.getState().currentEquipmentCrew.map((scheduleEquipment) => {
          if (scheduleEquipment.id === id) {
            return {
              ...scheduleEquipment,
              ...response.data.schedule_equipment,
            };
          }
          return scheduleEquipment;
        });

        set(() => ({ currentEquipmentCrew: updatedScheduleEquipment, loading: false }));
        return true;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  createLocation: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(locationsUrl, { location: { ...values } });
      if (response.data.success) {
        set(() => ({ hasErrors: false, loading: false }));
        return true;
      } else {
        set(() => ({ hasErrors: true, loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
});

useScheduleStore = devtools(useScheduleStore, { name: "ScheduleStore" });

export default useScheduleStore = create(useScheduleStore);
