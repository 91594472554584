import AssignFields from "./AssignFields";
import GetDirections from "./GetDirections";
import ShowAllFields from "./ShowAllFields";
import ShowAllEquipment from "./ShowAllEquipment";
import EditCrew from "./EditCrew";
import ShowHideWeatherPins from "./ShowHideWeatherPins";

import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";

import useCustomerMapPinStore from "../stores/customerMapPinStore";

export default function RightDrawer() {
  const crew = useCrewStore((state) => state.crew);
  const showRightMenu = useUIStore((state) => state.showRightMenu);
  const showCoverageMap = useUIStore((state) => state.showCoverageMap);
  const allowCreatePin = useCustomerMapPinStore((state) => state.allowCreatePin);

  const showHideCoverageMap = () => {
    useUIStore.setState({ showCoverageMap: !showCoverageMap });
  };

  return (
    <>
      {showRightMenu && (
        <div className="right-drawer">
          <div className="action-list">
            {crew && (
              <>
                <div className="action">
                  <div className="name">
                    <AssignFields />
                  </div>
                </div>
                <div className="action" onClick={showHideCoverageMap}>
                  <div className="name">
                    <i className="fa-solid fa-map"></i> COVERAGE MAP
                  </div>
                </div>
              </>
            )}
            <div className="action">
              <div className="name">
                <GetDirections />
              </div>
            </div>
            {crew && (
              <>
                <div className="action">
                  <div className="name">
                    <ShowAllFields />
                  </div>
                </div>
                <div className="action">
                  <div className="name">
                    <ShowAllEquipment />
                  </div>
                </div>
              </>
            )}
            <div className="action">
              <div className="name">
                <EditCrew />
              </div>
            </div>
            {crew && (
              <div
                className="action"
                onClick={() => {
                  useCustomerMapPinStore.setState({ allowCreatePin: !useCustomerMapPinStore.getState().allowCreatePin });
                  useUIStore.setState({ showSecondaryRightMenu: true, showRightMenu: false });
                }}>
                <div className="name">
                  <i className="fa-solid fa-map-pin"></i> {allowCreatePin ? "CANCEL PLACING PIN" : "PLACE PIN"}
                </div>
              </div>
            )}
            <ShowHideWeatherPins />
            <div className="action" onClick={() => window.location.reload(false)}>
              <div className="name">
                <i className="fa-solid fa-refresh"></i> REFRESH DATA
              </div>
            </div>
            {/* <div className="action" onClick={() => window.placeMe(-108.54972, 41, 1, 1, 1)}>
              <div className="name">
                <i className="fa-sharp fa-regular fa-face-explode"></i> DROP THE BOMB
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}
