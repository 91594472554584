import { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";

import useFieldStore from "../stores/fieldStore";
import useEquipmentStore from "../stores/equipmentStore";
import AssignFieldsForm from "../components/AssignFieldsForm";
import { MapContext } from "./MapLayer";
import { setReloadTimestamp } from "../lib/storage";

export default function AssignFields() {
  const mapContext = useContext(MapContext);

  const fetchCustomerFields = useFieldStore((state) => state.fetchCustomerFields);
  const updateAssignedFields = useFieldStore((state) => state.updateAssignedFields);
  const assigningFields = useFieldStore((state) => state.assigningFields);
  const tempAssignedFields = useFieldStore((state) => state.tempAssignedFields);
  const customerId = useEquipmentStore((state) => state.equipment?.customer_id);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (tempAssignedFields.length > 0 && mapContext.map && mapContext.fieldPreviewLayer) {
      tempAssignedFields.forEach(function (f) {
        mapContext.fieldPreviewLayer.addGeoJson(f.geojson);
      });

      mapContext.fieldPreviewLayer.setStyle({
        fillColor: "white",
        strokeWeight: 1,
        strokeColor: "white",
      });

      fitPreviewFields();
    }
  }, [tempAssignedFields, mapContext.map, mapContext.fieldPreviewLayer]);

  const fitPreviewFields = () => {
    const bounds = new window.google.maps.LatLngBounds();
    mapContext.fieldPreviewLayer.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    mapContext.map.fitBounds(bounds);
  };

  const reloadPage = () => {
    setReloadTimestamp(new Date());
    window.location.reload(false);
  };

  const ListAction = () => {
    if (assigningFields) {
      return (
        <>
          <button className="btn me-5" onClick={reloadPage}>
            Reset
          </button>
          <button className="btn" onClick={updateAssignedFields}>
            Apply To Crew
          </button>
        </>
      );
    } else {
      return (
        <div onClick={() => setShowModal(!showModal)}>
          <i className="fa-solid fa-arrow-right-arrow-left"></i> CHANGE FIELD
        </div>
      );
    }
  };

  const manageFarms = () => {
    const href = `${process.env.REACT_APP_ADMIN_URL}/customers/${customerId}/login`;
    window.open(href, "_blank");
  };

  return (
    <>
      {ListAction()}

      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showModal}
        onAfterOpen={
          () => fetchCustomerFields()
          /* Function that will be run after the modal has opened. */
        }
        contentLabel="Minimal Modal Example"
        style={{
          overlay: {
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Assign Fields to Crew</h4>

            <a
              className="modal-button left"
              href={`${process.env.REACT_APP_ADMIN_URL}/customers/${customerId}/login`}
              // href={`${process.env.REACT_APP_ADMIN_URL}/clients`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
              onClick={() => setShowModal(false)}>
              Manage Farms
            </a>
            <button className="modal-button" onClick={() => setShowModal(false)}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <div className="modal-content">
            <AssignFieldsForm setShowModal={setShowModal} />
          </div>
        </div>
      </ReactModal>
    </>
  );
}
