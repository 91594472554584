import AssignFields from "./AssignFields";
import useUIStore from "../stores/uiStore";
import useFieldStore from "../stores/fieldStore";

import useCustomerMapPinStore from "../stores/customerMapPinStore";

export default function SecondaryRightDrawer() {
  const showSecondaryRightMenu = useUIStore((state) => state.showSecondaryRightMenu);
  const allowCreatePin = useCustomerMapPinStore((state) => state.allowCreatePin);
  const assigningFields = useFieldStore((state) => state.assigningFields);

  return (
    <>
      {showSecondaryRightMenu && (
        <div className="right-drawer">
          <div className="action-list">
            {assigningFields && (
              <div className="action">
                <div className="name">
                  <AssignFields />
                </div>
              </div>
            )}
            {allowCreatePin && (
              <div
                className="action"
                onClick={() => {
                  useCustomerMapPinStore.setState({ allowCreatePin: !useCustomerMapPinStore.getState().allowCreatePin });
                  useUIStore.setState({ showSecondaryRightMenu: false, showRightMenu: true });
                }}>
                <div className="name">
                  <i className="fa-solid fa-map-pin"></i> {allowCreatePin ? "CANCEL PLACING PIN" : "PLACE PIN"}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
