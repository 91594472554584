import { useState } from "react";
import ReactModal from "react-modal";

import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";
import useEquipmentStore from "../stores/equipmentStore";
import CrewEquipmentForm from "../components/CrewEquipmentForm";

export default function EditCrew() {
  const crews = useCrewStore((state) => state.crews);
  const showEditCrewModal = useUIStore((state) => state.showEditCrewModal);
  const showCreateCrewModal = useUIStore((state) => state.showCreateCrewModal);
  const fetchCustomerEquipment = useEquipmentStore((state) => state.fetchCustomerEquipment);

  return (
    <>
      {crews.length > 0 && (
        <div onClick={() => useUIStore.setState({ showEditCrewModal: !showEditCrewModal })}>
          <i className="fa-solid fa-pencil"></i> EDIT CREW
        </div>
      )}

      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showEditCrewModal}
        onAfterOpen={
          // TODO: WIRE THIS UP TO ACTUAL CUSTOMER - CAN JUST REMOVE AND MAKE IT FROM DEVICE ID
          () => fetchCustomerEquipment()
          /* Function that will be run after the modal has opened. */
        }
        contentLabel="Minimal Modal Example"
        style={{
          overlay: {
            // backgroundColor: "papayawhip",
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Manage Crews</h4>
            <button onClick={() => useUIStore.setState({ showEditCrewModal: !showEditCrewModal })}>
              Close <i className="fa-solid fa-times"></i>
            </button>

            <button
              className="btn"
              style={{ float: "right" }}
              onClick={() => useUIStore.setState({ showCreateCrewModal: !showCreateCrewModal })}>
              Create New Crew
            </button>
          </div>
          <div className="modal-content has-double-pane">
            <CrewEquipmentForm />
          </div>
        </div>
      </ReactModal>
    </>
  );
}
