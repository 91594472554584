import { useEffect, useState, useContext } from "react";

import { MapContext } from "../components/MapLayer";

export default function GetDirections() {
  const mapContext = useContext(MapContext);

  const openDirections = () => {
    // window.open("https://maps.google.com/?daddr=" + mapContext.map.getCenter().lat() + "," + mapContext.map.getCenter().lng(), "_blank");
    window.location.href = "https://maps.google.com/?daddr=" + mapContext.map.getCenter().lat() + "," + mapContext.map.getCenter().lng();
  };

  return (
    <div onClick={openDirections}>
      <i className="fa-solid fa-diamond-turn-right"></i> GET DIRECTIONS
    </div>
  );
}
