import { useEffect } from "react";
import { useFormikContext } from "formik";
import { setOtherLoadSizeUsed, getOtherLoadSizeUsed } from "../lib/storage";

export default function MonitorLoadSizeChange({ setShowGrossAndTare }) {
  const {
    values: { load_size },
  } = useFormikContext();

  useEffect(() => {
    if (load_size === "other" || (load_size === "" && getOtherLoadSizeUsed() === "true")) {
      setShowGrossAndTare(true);
      setOtherLoadSizeUsed("true");
    } else {
      setShowGrossAndTare(false);
      setOtherLoadSizeUsed("false");
    }
  }, [load_size]);
}
