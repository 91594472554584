import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import AWN from "awesome-notifications";

import "react-datepicker/dist/react-datepicker.css";

import useScheduleStore from "../../stores/scheduleStore";
import { truckTypes, combineTypes } from "../../lib/equipmentTypes";

export default function ScheduleEquipmentForm({ schedule, setTabIndex }) {
  const updateEquipmentCrew = useScheduleStore((state) => state.updateEquipmentCrew);
  const fetchEquipmentAndCrewMemberOptions = useScheduleStore((state) => state.fetchEquipmentAndCrewMemberOptions);
  const currentEquipmentCrew = useScheduleStore((state) => state.currentEquipmentCrew);
  const loading = useScheduleStore((state) => state.loading);
  const equipmentOptions = useScheduleStore((state) => state.equipmentOptions);
  const equipmentOptionsIds = useScheduleStore((state) => state.equipmentOptionsIds);
  const currentlyAssignedEquipmentIds = useScheduleStore((state) => state.currentlyAssignedEquipmentIds);
  const crewMemberOptions = useScheduleStore((state) => state.crewMemberOptions);
  const locationOptions = useScheduleStore((state) => state.locationOptions);
  const [assignedEquipmentIds, setAssignedEquipmentIds] = useState([]);
  const [scheduleEquipmentValues, setScheduleEquipmentvalues] = useState([
    {
      equipment_id: "",
      crew_member_id: "",
      location_id: "",
      day_shift: false,
      day_shift_end_time: schedule.end_time,
      night_shift_crew_member_id: "",
      confirmed: false,
    },
  ]);

  useEffect(() => {
    fetchEquipmentAndCrewMemberOptions(schedule.id);
  }, [schedule, fetchEquipmentAndCrewMemberOptions]);

  // useEffect(() => {
  //   if (currentEquipmentCrew.length !== 0) {
  //     setScheduleEquipmentvalues(currentEquipmentCrew);
  //   }
  // }, [currentEquipmentCrew]);

  useEffect(() => {
    setAssignedEquipmentIds(equipmentOptionsIds.filter((item) => !currentlyAssignedEquipmentIds.includes(item)));
  }, [equipmentOptionsIds, currentlyAssignedEquipmentIds]);

  useEffect(() => {
    let allEquipmentAssignments = [];
    assignedEquipmentIds.forEach(function (id) {
      allEquipmentAssignments.push({
        equipment_name: equipmentOptions.find(obj => obj.id === id).name,
        equipment_id: id,
        crew_member_id: "",
        location_id: "",
        day_shift: false,
        day_shift_end_time: schedule.end_time,
        night_shift_crew_member_id: "",
        confirmed: false,
      });
    });

    allEquipmentAssignments = [...allEquipmentAssignments, ...currentEquipmentCrew];
    setScheduleEquipmentvalues(allEquipmentAssignments);
  }, [currentEquipmentCrew, assignedEquipmentIds]);

  const showEquipmentOption = (equipmentId, scheduleEquipment) => {
    return equipmentId === parseInt(scheduleEquipment.equipment_id);
  };

  const renderEquipmentOptions = (equipmentId, formValues, index) => {
    const selectedEquipment = formValues.schedule_equipment.map((value) => {
      return parseInt(value.equipment_id);
    });

    let equipmentSelectOptions = [];

    equipmentOptions.forEach((equipment) => {
      if (!selectedEquipment.includes(equipment.id) || showEquipmentOption(equipment.id, formValues.schedule_equipment[index])) {
        equipmentSelectOptions.push(
          <option key={equipment.id} value={equipment.id}>
            {equipment.name}
          </option>
        );
      }
    });

    return equipmentSelectOptions;
  };

  const filterCrewMemberOptions = (equipmentId) => {
    let equipmentType = equipmentOptions.filter((equipment) => {
      return equipment.id === parseInt(equipmentId);
    });

    let crewMembersSelectOptions = [];

    if (equipmentType.length !== 0) {
      equipmentType = equipmentType[0].equipment_type;

      crewMemberOptions.forEach((crewMember) => {
        if (
          // Weaponized logic for determing whether to actually show the crew member in this select
          (truckTypes.includes(equipmentType) && crewMember.allow_trucks) ||
          (combineTypes.includes(equipmentType) && crewMember.allow_combines)
        ) {
          crewMembersSelectOptions.push(
            <option key={crewMember.id} value={crewMember.id}>
              {formatCrewMemberOptionString(crewMember)}
            </option>
          );
        }
      });

      return crewMembersSelectOptions;
    }
  };

  const formatCrewMemberOptionString = (crewMember) => {
    if (crewMember.assigned_equipment !== "") {
      return crewMember.first_name + " " + crewMember.last_name + " - " + crewMember.assigned_equipment;
    } else {
      return crewMember.first_name + " " + crewMember.last_name;
    }
  };

  const showCrewMemberOption = (crewMemberId, scheduleEquipment) => {
    return crewMemberId === parseInt(scheduleEquipment.crew_member_id);
  };

  const renderCrewMemberOptions = (equipmentId, formValues, index) => {
    // The already selected crew members
    const selectedCrewMembers = formValues.schedule_equipment.map((value) => {
      return parseInt(value.crew_member_id);
    });

    // Get the selected equipment type so we can filter the applicable crew members.
    // .map() was breaking on this.
    let equipmentType = equipmentOptions.filter((equipment) => {
      return equipment.id === parseInt(equipmentId);
    });

    let crewMembersSelectOptions = [];

    if (equipmentType.length !== 0) {
      equipmentType = equipmentType[0].equipment_type;

      crewMemberOptions.forEach((crewMember) => {
        if (
          // Weaponized logic for determing whether to actually show the crew member in this select
          (!selectedCrewMembers.includes(crewMember.id) || showCrewMemberOption(crewMember.id, formValues.schedule_equipment[index])) &&
          ((truckTypes.includes(equipmentType) && crewMember.allow_trucks) ||
          (combineTypes.includes(equipmentType) && crewMember.allow_combines))
        ) {
          crewMembersSelectOptions.push(
            <option key={crewMember.id} value={crewMember.id}>
              {formatCrewMemberOptionString(crewMember)}
            </option>
          );
        }
      });
    }

    return crewMembersSelectOptions;
  };

  const validateNestedRecords = (scheduleEquipmentValues) => {
    let errorsFound = false;
    scheduleEquipmentValues.forEach((record, index) => {
      const recordErrors = validateRecord(record);
      if (Object.keys(recordErrors).length > 0) {
        errorsFound = true;
      }
    });

    return errorsFound;
  };

  // Validate each nested record
  const validateRecord = (values) => {
    const errors = {};
    if (values.day_shift) {
      if (!values.day_shift_end_time || values.day_shift_end_time === "") {
        errors.day_shift_end_time = "End time is required";
      }

      if (!values.night_shift_crew_member_id || values.night_shift_crew_member_id === "") {
        errors.night_shift_crew_member_id = "Night shift crew member is required";
      }
    }

    return errors;
  };

  const evalStatusColor = (assignment) => {
    if (assignment.confirmed) {
      return { backgroundColor: "green" };
    } else if (assignment.location_id == null || assignment.location_id === "") {
      return { backgroundColor: "darkred" };
    } else if (assignment.crew_member_id == null || assignment.crew_member_id === "") {
      return { backgroundColor: "darkorange" };
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          schedule_id: schedule.id,
          schedule_equipment: scheduleEquipmentValues,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // setSubmitting(true);
          const errorsFound = validateNestedRecords(values.schedule_equipment);

          if (errorsFound) {
            new AWN().alert("Please ensure all fields are filled out", { durations: { success: 3000 }, position: "top-right" });
          } else {
            const success = await updateEquipmentCrew(values, schedule.id);

            if (success) {
              resetForm();
              setTabIndex(0);
              useScheduleStore.setState({
                showEditForm: false,
                showScheduleEquipmentForm: false,
                showPrintSchedule: false,
                scheduleToEdit: null,
                currentEquipmentCrew: [],
              });
            } else {
              alert("Error updating the crew assignment");
            }
          }
          setSubmitting(false);
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FieldArray name="schedule_equipment">
              {({ push, remove }) => (
                <>
                  {values.schedule_equipment.sort((a, b) => a.equipment_name > b.equipment_name ? 1 : -1).map((_, index) => (
                    <div style={evalStatusColor(_)} key={index}>
                      <div key={index}>
                        <div className="row" key={index} style={{ paddingLeft: "1em" }}>
                          <div className="col" style={{ flexBasis: "5em" }}>
                            <label className="formik-label" htmlFor={`schedule_equipment[${index}].equipment_id`}>
                              Equipment
                            </label>
                            <Field name={`schedule_equipment[${index}].equipment_id`} as="select" disabled={true}>
                              <option value="">Select equipment</option>
                              {/* {renderEquipmentOptions()} */}
                              {renderEquipmentOptions(values.schedule_equipment[index].equipment_id, values, index)}
                            </Field>
                            <ErrorMessage name="crew_id" component="div" className="formik-error" />
                          </div>

                          <div className="col" style={{ flexBasis: "12em" }}>
                            <label className="formik-label" htmlFor={`schedule_equipment[${index}].crew_member_id`}>
                              Crew Member
                            </label>
                            <Field name={`schedule_equipment[${index}].crew_member_id`} as="select">
                              <option value="">Select a crew member</option>
                              {renderCrewMemberOptions(values.schedule_equipment[index].equipment_id, values, index)}
                            </Field>
                            <ErrorMessage name="crew_id" component="div" className="formik-error" />
                          </div>

                          <div className="col">
                            <label className="formik-label" htmlFor={`schedule_equipment[${index}].location_id`}>
                              Location
                            </label>
                            <Field name={`schedule_equipment[${index}].location_id`} as="select">
                              <option value="">Select location</option>
                              {locationOptions.map((location) => (
                                <option key={location.id} value={location.id}>
                                  {location.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="location_id" component="div" className="formik-error" />
                          </div>

                          <div className="col" style={{ flexBasis: "1em" }}>
                            <label className="formik-label" style={{ marginTop: "32px", fontSize: "20px" }}>
                              <Field name={`schedule_equipment[${index}].day_shift`} label="Day shift" type="checkbox" />
                              &nbsp;Day shift
                            </label>
                          </div>

                          <div className="col" style={{ flexBasis: "1em" }}>
                            <label className="formik-label" style={{ marginTop: "32px", fontSize: "20px" }}>
                              <Field name={`schedule_equipment[${index}].confirmed`} label="Confirmed" type="checkbox" />
                              &nbsp;Confirmed
                            </label>
                          </div>

                          {/* Removed on 6/23/23 */}
                          {/* <div className="col">
                          <button className="btn" style={{ marginTop: "20px" }} type="button" onClick={() => remove(index)}>
                            Remove
                          </button>
                        </div> */}
                        </div>

                        {values.schedule_equipment[index].day_shift && (
                          <div className="row" key={index + 1} style={{ paddingLeft: "1em" }}>
                            <div className="col" style={{ flexBasis: "5em" }}>
                              <label className="formik-label" htmlFor={`schedule_equipment[${index}].day_shift_end_time`}>
                                Day shift end time
                              </label>
                              <Field name={`schedule_equipment[${index}].day_shift_end_time`} label="Day shift end time" type="text" />
                              <ErrorMessage name="crew_id" component="div" className="formik-error" />
                            </div>

                            <div className="col" style={{ flexBasis: "12em" }}>
                              <label className="formik-label" htmlFor={`schedule_equipment[${index}].night_shift_crew_member_id`}>
                                Night shift crew member
                              </label>
                              <Field name={`schedule_equipment[${index}].night_shift_crew_member_id`} as="select" required={true}>
                                <option value="">Select a crew member</option>
                                {filterCrewMemberOptions(values.schedule_equipment[index].equipment_id)}
                              </Field>
                              <ErrorMessage name="crew_id" component="div" className="formik-error" />
                            </div>

                            <div className="col" />
                            <div className="col" />
                            <div className="col" />
                          </div>
                        )}
                        <hr />
                      </div>
                    </div>
                  ))}

                  {/* Removed on 6/23/23 */}
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      push({
                        equipment_id: "",
                        crew_member_id: "",
                        day_shift: false,
                        day_shift_end_time: schedule.end_time,
                        night_shift_crew_member_id: "",
                      })
                    }>
                    Add Schedule Equipment
                  </button> */}
                </>
              )}
            </FieldArray>

            <div className="action-stuck-bottom">
              <button type="submit" disabled={loading} className="btn btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
