import { useEffect } from "react";
import useAppStore from "../stores/appStore";
import useUIStore from "../stores/uiStore";

export default function ShowHideWeatherPins() {
  const fieldEntryTrackingEnabled = useAppStore((state) => state.fieldEntryTrackingEnabled);
  const showWeatherPins = useUIStore((state) => state.showWeatherPins);
  const toggleShowWeatherPins = useUIStore((state) => state.toggleShowWeatherPins);

  useEffect(() => {
    if (showWeatherPins) {
      document.body.classList.remove("hide-weather-pins");
      document.body.classList.remove("hide-field-entry-pins");
    } else {
      document.body.classList.add("hide-weather-pins");
      document.body.classList.add("hide-field-entry-pins");
    }
  }, [showWeatherPins]);

  const toggleWeatherPins = () => {
    toggleShowWeatherPins();
  };

  return (
    fieldEntryTrackingEnabled && (
      <div className="action">
        <div className="name">
          <div onClick={toggleWeatherPins}>
            <i className="fa-solid fa-cloud-sun"></i> {showWeatherPins ? "HIDE" : "SHOW"} WEATHER PINS
          </div>
        </div>
      </div>
    )
  );
}
