import create from "zustand";
import { devtools } from "zustand/middleware";

import useUIStore from "./uiStore";
import client from "../lib/client";
import { gasTicketsUrl } from "../lib/endpoints";

// let instead of const because we want to be able to use redux devtools
let useGasTicketStore = (set) => ({
  gasTickets: [],
  gasTicketQueue: [],
  loading: false,
  hasErrors: false,
  //isQueueTicket tracks if this create request is being performend on a ticket in the queue
  createGasTicket: async (values, isQueueTicket = false) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(gasTicketsUrl, { gas_ticket: { ...values } });
      if (response.data.success) {
        if (isQueueTicket) {
          console.log("created queue ticket");
          // If this is a queue ticket, remove it from the ticket queue
          set((state) => ({
            gasTicketQueue: state.gasTicketQueue.filter((ticket) => {
              return ticket.uid !== values.uid;
            }),
            loading: false,
          }));
        } else {
          console.log("created new ticket");
          useUIStore.setState({ showGasTicketModal: false, loading: false });
          set(() => ({ loading: false }));
        }
      } else {
        console.log("creation failed");
        useUIStore.setState({ showGasTicketModal: false, loading: false });
        set(() => ({ loading: false }));
      }
    } catch (err) {
      if (!isQueueTicket && err && err.message && err.message === "Network Error") {
        // If this is a queue ticket, do not add it back to the queue
        set((state) => ({ gasTicketQueue: [...state.gasTicketQueue, { ...values, uid: Date.now() }], loading: false }));
      }
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
});

export const processGasTicketQueue = async () => {
  const queue = useGasTicketStore.getState().gasTicketQueue;
  if (queue.length > 0) {
    await useGasTicketStore.getState().createGasTicket(queue[0], true);
  }
  setTimeout(processGasTicketQueue, 60000);
};

useGasTicketStore = devtools(useGasTicketStore, { name: "GasTicketStore" });

export default useGasTicketStore = create(useGasTicketStore);
