import { createConsumer } from "@rails/actioncable";
import { useEffect } from "react";
import useChatStore from "../stores/chatStore";
import useCrewStore from "../stores/crewStore";
import ChatContainer from "./ChatContainer";
import ChatIcon from "./ChatIcon";

export default function Chat() {
  const fetchMessages = useChatStore((state) => state.fetchMessages);
  const addMessage = useChatStore((state) => state.addMessage);
  const open = useChatStore((state) => state.open);
  const crewId = useCrewStore((state) => state.crew?.id);

  useEffect(() => {
    if (!crewId || window.messagesFetched) {
      return;
    }

    window.messagesFetched = true;

    fetchMessages(crewId);

    createConsumer(process.env.REACT_APP_CABLE_URL).subscriptions.create(
      { channel: "ChatMessagesChannel", crew_id: crewId },
      {
        connected: () => console.log("Chat messages cable connected"),
        disconnected: () => console.log("Chat messages cable disconnected"),
        received: ({ data }) => handleReceiveData(data.message),
      }
    );
  }, [window.messagesFetched, crewId]);

  const handleReceiveData = (message) => {
    addMessage(message);
  };

  return (
    <div className="chat">
      <ChatIcon />

      {open && <ChatContainer />}
    </div>
  );
}
