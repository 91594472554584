import { React } from "react";

import useUIStore from "../stores/uiStore";
import useEquipmentStore from "../stores/equipmentStore";
import useCrewStore from "../stores/crewStore";
import useLocationStore from "../stores/locationStore";
import Equipment from "./Equipment";
import { calculateDistanceAB } from "../lib/distance";
import { parseLatLong } from "../lib/parsing";

export default function EquipmentList() {
  const crew = useCrewStore((state) => state.crew);
  const crews = useCrewStore((state) => state.crews);
  const myEquipment = useEquipmentStore((state) => state.equipment);
  const crewEquipment = useEquipmentStore((state) => state.crewEquipment);
  const updateEquipment = useEquipmentStore((state) => state.updateEquipment);
  const sortByName = useEquipmentStore((state) => state.sortEquipmentByName);
  const sortByDistance = useEquipmentStore((state) => state.sortEquipmentByDistance);
  const currentLocation = useLocationStore((state) => state.myLocation);
  const hideCrewDrawer = useUIStore((state) => state.hideCrewDrawer);

  const evalCrewStatus = () => {
    if (crew != null) {
      return renderEquipment();
    } else {
      return renderAssignToCrew();
    }
  };

  const renderEquipment = () => {
    if (sortByName != null) {
      if (sortByName === "az") {
        return crewEquipment
          ?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
          .map((e, index) => {
            return !!myEquipment && myEquipment.id !== e.id ? (
              <Equipment key={index} equipmentName={e.name} location={e.last_location} equipment={e} />
            ) : (
              ""
            );
          });
      } else {
        return crewEquipment
          ?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1))
          .map((e, index) => {
            return !!myEquipment && myEquipment.id !== e.id ? (
              <Equipment key={index} equipmentName={e.name} location={e.last_location} equipment={e} />
            ) : (
              ""
            );
          });
      }
    } else if (sortByDistance != null) {
      if (sortByDistance === "nearest") {
        return crewEquipment
          ?.sort((a, b) =>
            calculateDistanceAB(
              currentLocation.lat,
              currentLocation.lng,
              parseLatLong(a.last_location).lat,
              parseLatLong(a.last_location).lng
            ) >
            calculateDistanceAB(
              currentLocation.lat,
              currentLocation.lng,
              parseLatLong(b.last_location).lat,
              parseLatLong(b.last_location).lng
            )
              ? 1
              : -1
          )
          .map((e, index) => {
            return !!myEquipment && myEquipment.id !== e.id ? (
              <Equipment key={index} equipmentName={e.name} location={e.last_location} equipment={e} />
            ) : (
              ""
            );
          });
      } else {
        return crewEquipment
          ?.sort((a, b) =>
            calculateDistanceAB(
              currentLocation.lat,
              currentLocation.lng,
              parseLatLong(a.last_location).lat,
              parseLatLong(a.last_location).lng
            ) <
            calculateDistanceAB(
              currentLocation.lat,
              currentLocation.lng,
              parseLatLong(b.last_location).lat,
              parseLatLong(b.last_location).lng
            )
              ? 1
              : -1
          )
          .map((e, index) => <Equipment key={index} equipmentName={e.name} location={e.last_location} equipment={e} />);
      }
    } else {
      return crewEquipment?.map((e, index) => <Equipment key={index} equipmentName={e.name} location={e.last_location} equipment={e} />);
    }
  };

  const renderAssignToCrew = () => {
    return (
      <>
        <div className="equipment assign-to-crew">
          <div className="name">This equipment has not been assigned to a crew. Please choose one.</div>
          <br />
          {renderCrews()}
        </div>
      </>
    );
  };

  const renderCrews = () => {
    return crews?.map((c, index) => (
      <div className="equipment" onClick={() => updateEquipment({ crew_id: c.id }, true)}>
        <div className="name orange">{c.name}</div>
      </div>
    ));
  };

  // return <div className="equipment-list">{evalCrewStatus()}</div>;
  return <>{!hideCrewDrawer && <div className="equipment-list">{evalCrewStatus()}</div>}</>;
}
