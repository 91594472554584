import { useEffect, useState } from "react";

import useScheduleStore from "../../stores/scheduleStore";
import Schedule from "./Schedule";
import ScheduleForm from "./ScheduleForm";
import ScheduleEquipmentForm from "./ScheduleEquipmentForm";

export default function ScheduleList({ setTabIndex }) {
  const fetchSchedules = useScheduleStore((state) => state.fetchSchedules);
  const schedules = useScheduleStore((state) => state.schedules);
  const scheduleToEdit = useScheduleStore((state) => state.scheduleToEdit);
  const showEditForm = useScheduleStore((state) => state.showEditForm);
  const showScheduleEquipmentForm = useScheduleStore((state) => state.showScheduleEquipmentForm);
  const loading = useScheduleStore((state) => state.loading);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const renderSchedules = () => {
    return schedules.map((schedule) => <Schedule key={schedule.id} value={schedule.id} schedule={schedule} />);
  };

  const renderForm = () => {
    if (showEditForm) {
      return <ScheduleForm schedule={scheduleToEdit} editing={true} />;
    } else if (showScheduleEquipmentForm) {
      return <ScheduleEquipmentForm schedule={scheduleToEdit} setTabIndex={setTabIndex} />;
    }
  };

  return (
    <>
      {!showEditForm && !showScheduleEquipmentForm ? (
        <>
          <table className="w-100 text-center" style={{ fontSize: "18px" }}>
            <thead>
              <tr>
                <th style={{ width: "18%" }}>Name</th>
                <th>Notes</th>
                <th>Start - End Times</th>
                <th style={{ width: "22%" }}></th>
              </tr>
            </thead>
            <tbody>{renderSchedules()}</tbody>
          </table>
        </>
      ) : (
        renderForm()
      )}
    </>
  );
}
