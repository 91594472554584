import { useEffect, useState, useContext } from "react";

import { parseLatLong } from "../lib/parsing";
import { MapContext } from "../components/MapLayer";
import { truckTypes, combineTypes } from "../lib/equipmentTypes";
import useEquipmentStore from "../stores/equipmentStore";

// let icons = {};

export default function EquipmentMarkers() {
  const mapContext = useContext(MapContext);
  const myEquipment = useEquipmentStore((state) => state.equipment);
  const animateMarkers = useEquipmentStore((state) => state.animateMarkers);
  const crewEquipment = useEquipmentStore((state) => state.crewEquipment);
  const lockToEquipment = useEquipmentStore((state) => state.lockToEquipment);

  const [markerList, setMarkerList] = useState({});
  const [iconElements, setIconElements] = useState({});
  const [initializedMarkers, setInitializedMarkers] = useState(false);

  // TODO: Dynamic info windows
  // const [timer, setTimer] = useState(null);

  // TODO: Dynamic info windows
  // useEffect(() => {
  //   let interval = null;
  //   interval = setInterval(() => {
  //     setTimer((timer) => timer + 1);
  //   }, 1000);
  // }, []);

  // TODO: Dynamic info windows
  // useEffect(() => {
  //   // console.log(timer);
  //   const marker = document.getElementById("equipment-marker-89");
  //   if (marker) {
  //     marker.innerHTML = timer;
  //   }
  // }, [timer]);

  const assignColors = (percentage, locationStatus) => {
    if (percentage === 150 || locationStatus === "RF") {
      return "pin-yellow";
    } else if (percentage === 0) {
      return "pin-gray";
    } else if (percentage < 51) {
      return "pin-green";
    } else if (percentage >= 51 && percentage < 80) {
      return "pin-orange";
    } else {
      return "pin-red";
    }
  };

  const assignDirection = (direction) => {
    if (isNaN(direction)) {
      return "north";
    } else if (direction >= 0 && direction < 22.5) {
      return "north";
    } else if (direction >= 22.5 && direction < 45) {
      return "north-north-east";
    } else if (direction >= 45 && direction < 67.5) {
      return "north-east";
    } else if (direction >= 67.5 && direction < 90) {
      return "east-north-east";
    } else if (direction >= 90 && direction < 112.5) {
      return "east";
    } else if (direction >= 112.5 && direction < 135) {
      return "east-south-east";
    } else if (direction >= 135 && direction < 157.5) {
      return "south-east";
    } else if (direction >= 157.5 && direction < 180) {
      return "south-south-east";
    } else if (direction >= 180 && direction < 202.5) {
      return "south";
    } else if (direction >= 202.5 && direction < 225) {
      return "south-south-west";
    } else if (direction >= 225 && direction < 247.5) {
      return "south-west";
    } else if (direction >= 247.5 && direction < 270) {
      return "west-south-west";
    } else if (direction >= 270 && direction < 292.5) {
      return "west";
    } else if (direction >= 292.5 && direction < 315) {
      return "west-north-west";
    } else if (direction >= 315 && direction < 337.5) {
      return "north-west";
    } else if (direction >= 337.5 && direction < 360) {
      return "north-north-west";
    } else {
      return "north";
    }
  };

  const getIconForEquipment = (equipmentType) => {
    switch (equipmentType) {
      case "semi":
        return "pin-icon-semi";
      case "dirt_wagon":
        return "pin-icon-semi";
      case "straight_truck":
        return "pin-icon-straight-truck";
      case "tractor_wagon":
        return "pin-icon-tractor-wagon";
      case "chopper":
        return "pin-icon-chopper";
      case "mower":
        return "pin-icon-mower";
      case "merger":
        return "pin-icon-merger";
      case "push_tractor":
        return "pin-icon-push-tractor";
      default:
        return "test";
    }
  };

  const assignIcons = (marker, equipment) => {
    if (iconElements[equipment.id] != null) {
      if (equipment.id === myEquipment.id) {
        iconElements[equipment.id].className = `circle pin-icon-you pin-white ${assignDirection(parseFloat(equipment.last_course))}`;
      } else {
        if (truckTypes.includes(equipment.equipment_type)) {
          iconElements[equipment.id].className = `circle ${getIconForEquipment(equipment.equipment_type)} ${assignColors(
            equipment.fill_percent,
            equipment.location_status
          )} ${assignDirection(parseFloat(equipment.last_course))}`;
        } else {
          iconElements[equipment.id].className = `circle ${getIconForEquipment(equipment.equipment_type)} pin-white ${assignDirection(
            parseFloat(equipment.last_course)
          )}`;
        }
      }
    }
  };

  const animatedMove = (marker, current, moveTo) => {
    let deltalat = (moveTo.lat - current.lat) / 100;
    let deltalng = (moveTo.lng - current.lng) / 100;

    for (let i = 0; i < 100; i++) {
      (function (ind) {
        setTimeout(function () {
          let lat = marker.position.lat;
          let lng = marker.position.lng;

          lat += deltalat;
          lng += deltalng;
          marker.position = { lat: lat, lng: lng };
        }, 10 * ind);
      })(i);
    }
  };

  // Draws markers onto the map and also handles deletion
  useEffect(() => {
    if (initializedMarkers && crewEquipment && animateMarkers) {
      crewEquipment.forEach(function (equipment) {
        // console.log(iconElements[equipment.id]);

        // if (iconElements[equipment.id].className === "circle") {
        //   iconElements[equipment.id].className = "circle-2";
        // }

        assignIcons(markerList[equipment.id], equipment);

        if (markerList[equipment.id] != null) {
          animatedMove(
            markerList[equipment.id],
            { lat: markerList[equipment.id].position.lat, lng: markerList[equipment.id].position.lng },
            parseLatLong(equipment.last_location)
          );
        }

        if (lockToEquipment != null && lockToEquipment === equipment.id) {
          mapContext.map.setCenter(parseLatLong(equipment.last_location));
        }
      });

      useEquipmentStore.setState({ animateMarkers: false });
    }
  }, [initializedMarkers, animateMarkers]);

  // Draws markers onto the map and also handles deletion
  useEffect(() => {
    if (mapContext.map && crewEquipment != null && crewEquipment.length > 0 && !initializedMarkers) {
      // markeys.forEach(function (pin) {
      //   pin.setMap(null);
      // });

      // let markerList = [];
      let markers = {};
      let icons = {};
      crewEquipment.forEach(function (equipment) {
        if (equipment.last_location) {
          icons[equipment.id] = document.createElement("div");

          // This was originally set to only work after a ping update.
          // We can DRY this later, but it would involve refactoring the way the function (assignIcons) setting it works.
          if (equipment.id === myEquipment.id) {
            icons[equipment.id].className = `circle pin-icon-you pin-white ${assignDirection(parseFloat(equipment.last_course))}`;
          } else {
            if (truckTypes.includes(equipment.equipment_type)) {
              icons[equipment.id].className = `circle ${getIconForEquipment(equipment.equipment_type)} ${assignColors(
                equipment.fill_percent
              )} ${assignDirection(parseFloat(equipment.last_course))}`;
            } else {
              icons[equipment.id].className = `circle ${getIconForEquipment(equipment.equipment_type)} pin-white ${assignDirection(
                parseFloat(equipment.last_course)
              )}`;
            }
          }

          const marker = new window.google.maps.marker.AdvancedMarkerView({
            map: mapContext.map,
            position: parseLatLong(equipment.last_location),
            content: icons[equipment.id],
          });

          marker.addListener("tap", () => {
            marker.infowindow.open(mapContext.map, marker);
          });
          marker.addListener("click", () => {
            marker.infowindow.open(mapContext.map, marker);
          });

          marker.infowindow = new window.google.maps.InfoWindow({
            content: `<div style="color: #000000;"><div class="pin-note">${equipment.name}</div></div>`,
            pixelOffset: new window.google.maps.Size(0, 30),
            // TODO: Dynamic info window
            // content: `<div style="color: #000000;"><div class="pin-note" id="equipment-marker-${equipment.id}">${timer}</div></div>`,
          });

          // TODO: Dynamic info window
          // If equipment has the timer timestamp not null
          // marker.infowindow.open(mapContext.map, marker);

          // icons[equipment.id] = equipmentIcon;
          markers[equipment.id] = marker;
        }
      });

      setMarkerList(markers);
      setIconElements(icons);
      setInitializedMarkers(true);
    }
  }, [mapContext.map, crewEquipment]);
}
