import { useEffect, useState, useRef, createContext } from "react";
import useEquipmentStore from "../stores/equipmentStore";
import useUIStore from "../stores/uiStore";

// move to new map component
export const MapContext = createContext({ map: null, drawlingLayer: null });
// let center = null;

export default function MapLayer({ center, zoom, children }) {
  const [map, setMap] = useState(null);
  const [drawingLayer, setDrawingLayer] = useState(null);
  const [fieldLayer, setFieldLayer] = useState(null);
  const [fieldPreviewLayer, setFieldPreviewLayer] = useState(null);
  const [dumpLayer, setDumpLayer] = useState(null);
  const [coverageLayer, setCoverageLayer] = useState(null);
  const [weatherLayer, setWeatherLayer] = useState(null);
  // not currently used
  const [pinLayer, setPinLayer] = useState(null);
  // not currently used
  const [lineLayer, setLineLayer] = useState(null);
  const ref = useRef();

  useEffect(() => {
    const mappy = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      mapTypeId: "hybrid",
      disableDefaultUI: true,
      mapId: "4504f8b37365c3d0",
    });

    mappy.addListener("dragstart", function () {
      useEquipmentStore.setState({ focusedEquipment: null, lockToEquipment: null });
    });

    mappy.addListener("zoom_changed", function () {
      useUIStore.setState({ mapZoomLevel: mappy.getZoom() });
    });

    const fieldyLayer = new window.google.maps.Data();
    fieldyLayer.setMap(mappy);
    setFieldLayer(fieldyLayer);
    const fieldPreviewLayer = new window.google.maps.Data();
    fieldPreviewLayer.setMap(mappy);
    setFieldPreviewLayer(fieldPreviewLayer);
    const dumpyLayer = new window.google.maps.Data();
    dumpyLayer.setMap(mappy);
    setDumpLayer(dumpyLayer);
    const lineyLayer = new window.google.maps.Data();
    lineyLayer.setMap(mappy);
    setLineLayer(lineyLayer);
    const coverageLayer = new window.google.maps.Data();
    coverageLayer.setMap(mappy);
    setCoverageLayer(coverageLayer);
    const weatherLayer = new window.google.maps.Data();
    weatherLayer.setMap(mappy);
    setWeatherLayer(weatherLayer);
    const pinLayer = new window.google.maps.Data();
    pinLayer.setMap(mappy);
    setPinLayer(pinLayer);

    setMap(mappy);

    // const drawy = new window.google.maps.drawing.DrawingManager();
    const drawy = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYLINE,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.BOTTOM_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYLINE],
      },
      polylineOptions: {
        editable: true,
        draggable: true,
        strokeColor: "#1E90FF",
        strokeWeight: 5,
        strokeOpacity: 0.9,
      },
    });

    // drawy.setMap(mappy);
    setDrawingLayer(drawy);
  }, []);

  // This is for auto-setting map center. We aren't using it currently.
  // useEffect(() => {
  //   if (map != null) {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const pos = {
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude,
  //           };

  //           map.setCenter(pos);
  //         },
  //         () => {
  //           console.log("test");
  //         }
  //       );
  //     } else {
  //       // Browser doesn't support Geolocation
  //       console.log("error");
  //     }
  //   }
  // }, [map]);

  return (
    <MapContext.Provider
      value={{
        map: map,
        drawingLayer: drawingLayer,
        fieldLayer: fieldLayer,
        fieldPreviewLayer: fieldPreviewLayer,
        dumpLayer: dumpLayer,
        lineLayer: lineLayer,
        coverageLayer: coverageLayer,
        pinLayer: pinLayer,
        weatherLayer: weatherLayer,
      }}>
      <div ref={ref} id="map" />
      {children}
    </MapContext.Provider>
  );
}
