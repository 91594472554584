/**
 * Attach a focus event listener for a ref that will clear out input if the value is 0.
 *
 * This allows for easier input, not forcing the user to manually delete the placeholder 0
 * in the input.
 */
export function watchFocus(e) {
  try {
    e.addEventListener("focus", handleFocus);
  } catch (e) {
    console.log("Could not attach event listner to gallons: ", e);
  }
}

/**
 * Set an input's value to blank ("") if the current value is 0
 */
function handleFocus(e) {
  try {
    const val = Number.parseInt(e.target.value);

    if (val !== 0) {
      return;
    }

    e.target.value = "";
  } catch (e) {
    console.log("Could not evaluate input focus: ", e);
  }
}

export default watchFocus;
