import create from "zustand";
import { devtools } from "zustand/middleware";

import client, { noIntercept } from "../lib/client";
// import { dumpLocationsUrl } from "../lib/endpoints";
import { weatherStatusesUrl } from "../lib/endpoints";
import useEquipmentStore from "./equipmentStore";
import useFieldStore from "./fieldStore";
import useCrewStore from "./crewStore";
import { setTemperatureLogTime } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useWeatherStore = (set) => ({
  haveFetchedWeather: false,
  today: "",
  tonight: "",
  loading: false,
  hasErrors: false,
  weatherStatuses: [],
  stopFetching: false,
  haveFetchedWeatherStatuses: false,
  ranLast: null,
  fetchWeather: async (deviceLocation) => {
    set(() => ({ loading: true }));
    try {
      const grid = await noIntercept.get(`https://api.weather.gov/points/${deviceLocation.lat},${deviceLocation.lng}`);
      if (grid?.data?.properties?.forecast) {
        const response = await noIntercept.get(grid?.data?.properties?.forecast);
        if (response?.data?.properties?.periods) {
          set((state) => ({
            haveFetchedWeather: true,
            today: response.data.properties.periods[0].detailedForecast,
            tonight: response.data.properties.periods[1].detailedForecast,
          }));
        }
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  logCurrentWeather: async (deviceLocation) => {
    const equipment = useEquipmentStore.getState().equipment;
    try {
      const grid = await noIntercept.get(`https://api.weather.gov/points/${deviceLocation.lat},${deviceLocation.lng}`);
      if (grid?.data?.properties?.forecastHourly) {
        const fetch_response = await noIntercept.get(grid?.data?.properties?.forecastHourly);
        if (fetch_response?.data?.properties?.periods) {
          // set((state) => ({
          //   haveFetchedWeather: true,
          //   today: response.data.properties.periods[0].detailedForecast,
          //   tonight: response.data.properties.periods[1].detailedForecast,
          // }));
          const summary = `${fetch_response.data.properties.periods[0].shortForecast}.<br>${fetch_response.data.properties.periods[0].temperature}F.<br>${fetch_response.data.properties.periods[0].relativeHumidity.value}% humidity.<br>Wind ${fetch_response.data.properties.periods[0].windSpeed} ${fetch_response.data.properties.periods[0].windDirection}`;
          try {
            await client.post(weatherStatusesUrl, {
              weather_status: {
                equipment_id: equipment.id,
                device_name: equipment.device_name,
                crew_id: equipment.crew_id,
                sent_at: new Date(),
                summary: summary,
                course: deviceLocation.course,
              },
              longitude: deviceLocation.lng,
              latitude: deviceLocation.lat,
              weather_data: fetch_response.data.properties.periods[0],
            });
            setTemperatureLogTime(new Date());
          } catch (err) {}
        }
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchWeatherStatuses: async (timeSince) => {
    try {
      set((state) => ({ stopFetching: true }));
      const response = await client.get(`${weatherStatusesUrl}?since=${timeSince}`);
      if (!!response.data.weather_statuses) {
        set((state) => ({ weatherStatuses: [...response.data.weather_statuses], haveFetchedWeatherStatuses: true }));
      }

      // Set the field entry point data here and update the store value in the field store.
      // This is just piggybacking off of the featchWeatherStatuses logic since it's already
      // implemented. We don't need to keep a running list of all the old and new fieldEntryPoints,
      // just the new ones, as the markers for the old ones will have been already created and placed
      // on the map.
      if (!!response.data.field_entry_points && response.data.field_entry_points.length > 0) {
        useFieldStore.setState({
          fieldEntryPoints: [...response.data.field_entry_points],
        });
      }
    } catch (err) {
      set(() => ({ hasErrors: true }));
    }
  },
});

// This is necessary because the crew is not set at the time of the initial run.
// We need to have a shorter interval until we actually get the first batch of data.
export const processInitialWeatherStatuses = async () => {
  if (useCrewStore.getState().crew != null && !useWeatherStore.getState().haveFetchedWeatherStatuses) {
    useWeatherStore.getState().fetchWeatherStatuses(null);
    useWeatherStore.getState().ranLast = new Date().toISOString();
  }

  if (!useWeatherStore.getState().haveFetchedWeatherStatuses) {
    setTimeout(processInitialWeatherStatuses, 10000);
  } else {
    processWeatherStatuses(false);
  }
};

// After fetching the first batch of weather statuses and field entry points, switch to polling
// every 15 minutes
export const processWeatherStatuses = async () => {
  if (useCrewStore.getState().crew != null)
    useWeatherStore.getState().haveFetchedWeatherStatuses
      ? useWeatherStore.getState().fetchWeatherStatuses(useWeatherStore.getState().ranLast)
      : useWeatherStore.getState().fetchWeatherStatuses(null);
  useWeatherStore.getState().ranLast = new Date().toISOString();
  setTimeout(processWeatherStatuses, 900000); //900000 = 15 minutes
};

useWeatherStore = devtools(useWeatherStore, { name: "WeatherStore" });

export default useWeatherStore = create(useWeatherStore);
