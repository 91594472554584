import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "react-datepicker/dist/react-datepicker.css";

import useScheduleStore from "../../stores/scheduleStore";
import useCrewStore from "../../stores/crewStore";

export default function ScheduleForm({ schedule = null, editing = false }) {
  const createSchedule = useScheduleStore((state) => state.createSchedule);
  const updateSchedule = useScheduleStore((state) => state.updateSchedule);
  const fetchFields = useScheduleStore((state) => state.fetchFields);
  const loading = useScheduleStore((state) => state.loading);
  const crew = useCrewStore((state) => state.crew);
  const fields = useScheduleStore((state) => state.fieldOptions);

  const initialValues = {
    customer_id: crew.customer_id,
    name: "",
    start_time: "",
    end_time: "",
    notes: "",
  };

  const handleCrewChange = async (crewId, setFieldCallback) => {
    setFieldCallback("crew_id", crewId);
    fetchFields(crewId);
  };

  return (
    <div>
      <Formik
        enableReinitialize={false}
        initialValues={editing ? { ...schedule } : initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.name || values.name === "") {
            errors.name = "Required";
          }
          if (!values.start_time || values.start_time === "") {
            errors.start_time = "Required";
          }
          if (!values.end_time || values.end_time === "") {
            errors.end_time = "Required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          let success = false;
          if (editing && schedule != null) {
            success = await updateSchedule(values, schedule.id);
          } else {
            success = await createSchedule(values);
          }
          if (success) {
            resetForm();
            alert(`Schedule ${editing ? "updated!" : "created!"}`);
          } else {
            alert("Error submitting form");
          }
          setSubmitting(false);
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="city">
                    Name
                  </label>
                  <Field name="name" label="name" type="text" />
                  <ErrorMessage name="name" component="div" className="formik-error" />
                </div>

                <div className="col">
                  <label className="formik-label" htmlFor="notes">
                    Notes
                  </label>
                  <Field name="notes" label="notes" type="text" />
                  <ErrorMessage name="notes" component="div" className="formik-error" />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="start_time">
                    Start time
                  </label>
                  <Field name="start_time" label="start_time" type="text" />
                  <ErrorMessage name="start_time" component="div" className="formik-error" />
                </div>

                <div className="col">
                  <label className="formik-label" htmlFor="end_time">
                    Day shift end time
                  </label>
                  <Field name="end_time" label="end_time" type="text" />
                  <ErrorMessage name="end_time" component="div" className="formik-error" />
                </div>
              </div>
            </div>

            <div className="action-stuck-bottom">
              <button type="submit" disabled={loading} className="btn btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
