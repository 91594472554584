import useChatStore from "../stores/chatStore";
import { submitMessage } from "../stores/chatStore";

export default function ChatSubmit() {
  const newMessage = useChatStore((store) => store.newMessage);

  const handleClick = () => {
    submitMessage();
  };

  return (
    <div className="chat-submit">
      <button className="btn submit" onClick={handleClick} disabled={!!!newMessage}>
        Send
      </button>
    </div>
  );
}
