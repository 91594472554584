import ReactModal from "react-modal";
import { useState } from "react";
import useUIStore from "../stores/uiStore";

export default function DisclaimerModal() {
  const showModal = useUIStore((state) => state.showDisclaimerModal);

  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={showModal}
        contentLabel="My Profile"
        style={{
          overlay: {
            zIndex: 99999998,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h1 className="text-center">Terms</h1>
          </div>
          <div className="modal-content">
            <div className="container">
              <div className="row align-stretch">
                <div className="col-12">
                  <div className="profile-card">
                    <h2>Choice of Law and Forum Selection Clause</h2>
                    <div className="profile-info">
                      Any litigation arising out of or related to this Agreement must be brought in the Clark County Ohio Common Pleas
                      Court. The parties hereby waive any objection to the jurisdiction and venue of such court and agree to submit to the
                      jurisdiction of the Clark County Ohio Common Pleas Court. This agreement shall be governed by and construed in
                      accordance with the laws of the State of Ohio, without regard to its conflict of law principles.
                    </div>

                    <h2>Notice and Waiver Regarding App Usage</h2>
                    <div className="profile-info">
                      <p>
                        <span style={{ fontWeight: "bold" }}>Notice: </span> Do not use the Pipe Ag app while operating a vehicle or any
                        machinery. Operating a vehicle or machinery requires your full attention, and using the app simultaneously can
                        result in serious accidents or injuries.
                      </p>

                      <p>
                        <span style={{ fontWeight: "bold" }}>Waiver: </span>
                        By using the Pipe Ag app, the user, their agents, employer, and employees agree to hold Pipe Ag harmless from any
                        and all claims, damages, or liabilities arising from the use of the app while operating a vehicle or machinery.
                      </p>

                      <p>
                        <span style={{ fontWeight: "bold" }}>Indemnification: </span>The user agrees to indemnify, defend, and hold Pipe Ag,
                        its officers, directors, employees, and agents harmless from any and all claims, damages, losses, liabilities,
                        costs, and expenses (including reasonable attorneys' fees) arising out of or related to the user's use of the app
                        while operating a vehicle or machinery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-stretch">
                <div className="col-12">
                  <div className="action-stuck-bottom">
                    <button type="submit" className="btn btn-primary" onClick={() => useUIStore.setState({ showDisclaimerModal: false })}>
                      I have read, acknowledged, and agree to these terms
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
