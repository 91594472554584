import { useEffect, useState } from "react";

import useLocationStore from "../stores/locationStore";
import useEquipmentStore from "../stores/equipmentStore";
import { calculateDistanceAB } from "../lib/distance";
import { parseLatLong } from "../lib/parsing";
import { truckTypes, combineTypes } from "../lib/equipmentTypes";

export default function NearbyEquipment() {
  const equipment = useEquipmentStore((state) => state.equipment);
  const crewEquipment = useEquipmentStore((state) => state.crewEquipment);
  const equipmentStatus = useEquipmentStore((state) => state.equipmentStatus);
  const nearbyEquipment = useEquipmentStore((state) => state.nearybyEquipment);
  const currentLocation = useLocationStore((state) => state.myLocation);
  const updateEquipmentLoadingTimestamp = useEquipmentStore((state) => state.updateEquipmentLoadingTimestamp);

  // const [activeChopper, setActiveChopper] = useState(null);
  const activeChopper = useEquipmentStore((state) => state.activeChopper);
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);
  // const [percentFull, setPercentFull] = useState(0);
  const percentFull = useEquipmentStore((state) => state.percentFull);

  const [full, setFull] = useState(false);

  useEffect(() => {
    if (crewEquipment && crewEquipment.length > 0) {
      if (truckTypes.includes(equipment.equipment_type)) {
        findNearestEquipment(combineTypes);
      } else if (combineTypes.includes(equipment.equipment_type)) {
        findNearestEquipment(truckTypes);
      }
    }
  }, [crewEquipment]);

  // Sorting by distance to all equipment
  const findNearestEquipment = (equipmentTypes) => {
    const sortedByDistance = crewEquipment?.sort((a, b) =>
      calculateDistanceAB(currentLocation.lat, currentLocation.lng, parseLatLong(a.last_location).lat, parseLatLong(a.last_location).lng) >
      calculateDistanceAB(currentLocation.lat, currentLocation.lng, parseLatLong(b.last_location).lat, parseLatLong(b.last_location).lng)
        ? 1
        : -1
    );

    let nearbyCount = 0;
    let nearby = [];
    sortedByDistance.some(function (e) {
      if (e.last_location) {
        const distance = calculateDistanceAB(
          currentLocation.lat,
          currentLocation.lng,
          parseLatLong(e.last_location).lat,
          parseLatLong(e.last_location).lng,
          "feet"
        );

        if (nearbyCount < 2 && equipmentTypes.includes(e.equipment_type) && distance <= 50) {
          nearby.push({ ...e, distance: distance });
          nearbyCount += 1;
        }

        if (nearbyCount === 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    if (nearby.length >= 1 && !full) {
      if (truckTypes.includes(equipment.equipment_type)) {
        if (equipmentStatus === "F" && activeChopper == null) {
          // setActiveChopper(nearby[0]);
          useEquipmentStore.setState({ activeChopper: nearby[0] });
          setTimerActive(true);
          updateEquipmentLoadingTimestamp(new Date());
        }
        // We're going to hold off on pausing the loading state. Re-enable this if we want to start with pausing again.
        // else if (!timerActive && activeChopper.id === nearby[0].id) {
        //   // ^ we may need to update this if it's possible to still be in loading range of a chopper
        //   // but physically closer to a different chopper than the one actively loading from
        //   setTimerActive(true);
        // } else if (timerActive && activeChopper.id !== nearby[0].id) {
        //   setTimerActive(false);
        // }
      } else {
        // Current equipment is a chopper.
        // I'm not sure if we need to do anything specific here but the previous logic is what was causing the
        // chopper placeMe requests to blow up.
      }

      // setActiveChopper(nearby[0]);
    }

    useEquipmentStore.setState({ nearbyEquipment: nearby });
  };

  // useEffect(() => {
  //   if (activeChopper) {
  //     console.log("active chopper: ", activeChopper);
  //   }
  // }, [activeChopper]);

  const resetTimer = () => {
    setTimer(0);
    setTimerActive(false);
  };

  useEffect(() => {
    if (timer) {
      // console.log(timer);
      // console.log(Math.round((timer / 135) * 100) + "%");
      // setPercentFull(Math.round((timer / 135) * 100));

      // CHANGED FROM 2:15 to 5:00 fill time on 6/12/23
      useEquipmentStore.setState({ percentFull: Math.round((timer / 300) * 100) });
      if (timer === 300) {
        setFull(true);
        resetTimer();
      }
    }
  }, [timer]);

  useEffect(() => {
    let interval = null;
    if (timerActive) {
      interval = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else if (!timerActive && timer !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerActive, timer]);

  // When the truck exits the field, we need to reset the data so it can start loading again
  useEffect(() => {
    if (equipmentStatus === "RD") {
      setFull(false);
      setTimerActive(false);
      setTimer(0);
      useEquipmentStore.setState({ activeChopper: null });
    }
  }, [equipmentStatus]);
}
