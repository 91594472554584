import useEquipmentStore from "../stores/equipmentStore";
import useFieldStore from "../stores/fieldStore";

export default function HeaderInfo() {
  const equipment = useEquipmentStore((state) => state.equipment);
  const crewFields = useFieldStore((state) => state.crewFields);

  const renderDeviceName = () => {
    if (equipment) {
      return equipment.name + " (" + equipment.device_name + ") ";
    } else {
      return "";
    }
  };

  const renderFieldNames = () => {
    if (crewFields) {
      return crewFields.map((f) => `${f.name}`).join(", ");
    } else {
      return "";
    }
  };

  const renderInfo = () => {
    return `${renderDeviceName()} ${renderFieldNames()}`;
  };

  return renderInfo();
}
