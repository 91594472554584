import { useEffect, useState, useContext, useRef } from "react";

import { parseLatLong } from "../lib/parsing";
import { MapContext } from "../components/MapLayer";
import MapPinModal from "../components/MapPinModal";
import useUIStore from "../stores/uiStore";
import useCustomerMapPinStore from "../stores/customerMapPinStore";

export default function MapPins() {
  const mapContext = useContext(MapContext);
  const customerMapPins = useCustomerMapPinStore((state) => state.customerMapPins);
  const allowCreatePin = useCustomerMapPinStore((state) => state.allowCreatePin);
  const createPinRef = useRef({});
  createPinRef.current = allowCreatePin;

  const [markeys, setMarkeys] = useState([]);
  const [showPinForm, setShowPinForm] = useState(false);

  useEffect(() => {
    if (mapContext.map) {
      mapContext.map.addListener("click", (event) => handlePinCreate(event));
    }
  }, [mapContext.map]);

  // Draws markers onto the map and also handles deletion
  useEffect(() => {
    if (mapContext.map) {
      markeys.forEach(function (pin) {
        pin.setMap(null);
      });

      let markerList = [];
      customerMapPins.forEach(function (pin) {
        let marker = new window.google.maps.Marker({
          position: parseLatLong(pin.position),
          draggable: false,
          map: mapContext.map,
          icon: `https://maps.google.com/mapfiles/ms/icons/${pin.icon_value}.png`,
        });

        marker.parseData = { id: pin.id };

        marker.addListener("tap", () => {
          marker.infowindow.open(mapContext.map, marker);
        });
        marker.addListener("click", () => {
          marker.infowindow.open(mapContext.map, marker);
        });

        marker.infowindow = new window.google.maps.InfoWindow({
          content: `<div style="color: #000000;"><div class="pin-note">${
            pin.note
          }</div><div class="remove-pin-button"><a href="#delete_pin" class="remove-pin-button-icon delete-pin" onclick='deletePin("${
            pin.id
          }")' style="z-index:999999" data-lat="${marker.position.lat()}" data-lng="${marker.position.lng()}"/>Delete Pin</a></div></div>`,
        });

        markerList.push(marker);
      });

      setMarkeys(markerList);
    }
  }, [mapContext.map, customerMapPins]);

  const handlePinCreate = (event) => {
    if (createPinRef.current) {
      useCustomerMapPinStore.setState({ newPinCoords: event.latLng.toJSON() });
      useUIStore.setState({ showPinModal: true });
    }
  };

  return <MapPinModal />;
}
