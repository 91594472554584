// Parse lat and long from a postGIS string that looks like: "POINT (-83.04088767625674 40.239684046283585)"
// Expects long, lat
export function parseLatLong(point) {
  if (point != null) {
    const split = point.split(" ");
    const long = Number(split[1].slice(1));
    const lat = Number(split[2].slice(0, -1));

    return { lat: lat, lng: long };
  } else {
    return { lat: null, lng: null };
  }
}

export function speedDisplay(val) {
  const speed = parseFloat(val);
  if (speed < 0) {
    return "0";
  } else {
    return speed.toFixed(1);
  }
}
