import AWN from "awesome-notifications";

export default function alert(msg) {
  new AWN().confirm("<div>" + msg + "</div>", () => {}, false, {
    icons: {
      enabled: false,
    },
    durations: {
      global: 5000,
    },
    labels: {
      confirm: "",
    },
  });
}

export function confirm(msg, confirmAction, cancelAction = () => {}) {
  new AWN().confirm("<div>" + msg + "</div>", confirmAction, cancelAction, false, {
    icons: {
      enabled: false,
    },
    durations: {
      global: 5000,
    },
    labels: {
      confirm: "",
    },
  });
}
