import useAppStore from "../stores/appStore";

export default function LoadingSpinner() {
  const globalLoading = useAppStore((state) => state.globalLoading);

  return (
    <>
      {globalLoading && (
        <>
          <div className="lds-circle">
            <div></div>
            <p style={{ textAlign: "center" }}>Loading...</p>
          </div>

          <div className="backdrop-blur"></div>
        </>
      )}
    </>
  );
}
