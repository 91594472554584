import { useEffect, useState, useContext } from "react";

import useLocationStore from "../stores/locationStore";
import { MapContext } from "../components/MapLayer";
import { getLastLocation, getShowFields, setShowFields } from "../lib/storage";

export default function CenterMap({ dumpLocation }) {
  const mapContext = useContext(MapContext);
  const centerMapCoords = useLocationStore((state) => state.centerMapCoords);
  const [loaded, setLoaded] = useState(false);

  const fitFields = () => {
    const bounds = new window.google.maps.LatLngBounds();
    mapContext.fieldLayer.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    mapContext.map.fitBounds(bounds);
  };

  // Center immediately based on url param values
  useEffect(() => {
    if (mapContext.map && !loaded) {
      const queryString = window.location.search;
      const deviceLocation = new URLSearchParams(queryString).get("c");
      const lastLocation = getLastLocation();
      let coords;

      if (!!lastLocation) {
        const parsedLastLocation = JSON.parse(lastLocation);
        coords = { lat: parseFloat(parsedLastLocation.lat), lng: parseFloat(parsedLastLocation.lng) };
      } else {
        if (deviceLocation) {
          const splitCoords = deviceLocation.split(",");
          coords = { lat: parseFloat(splitCoords[1]), lng: parseFloat(splitCoords[0]) };
        } else {
          coords = { lat: 39.8283, lng: -98.5795 };
        }
      }

      useLocationStore.setState({ centerMapCoords: coords });

      mapContext.map.setZoom(15);
      if (getShowFields() === "true") {
        fitFields();
        setShowFields(false);
      } else {
        mapContext.map.setCenter(coords);
      }

      setLoaded(true);
    }
  }, [mapContext]);

  // This can set map center automatically based on the centerMapCoords store value changing
  // useEffect(() => {
  //   if (centerMapCoords.lat != null && centerMapCoords.lng != null) {
  //     mapContext.map.setZoom(15);
  //     mapContext.map.panTo(centerMapCoords);
  //   }
  // }, [centerMapCoords]);
}
