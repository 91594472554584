import CrewDrawerHeading from "./CrewDrawerHeading";
import EquipmentList from "./EquipmentList";

export default function CrewDrawer() {
  return (
    <div className="crew-drawer">
      <CrewDrawerHeading />
      <EquipmentList />
    </div>
  );
}
