import ReactModal from "react-modal";

import useUIStore from "../stores/uiStore";
import useCustomerMapPinStore from "../stores/customerMapPinStore";
import MapPinForm from "./MapPinForm";

export default function MapPinModal() {
  const showPinModal = useUIStore((state) => state.showPinModal);

  const closeModalAndResetPin = () => {
    useUIStore.setState({ showPinModal: false, showSecondaryRightMenu: false, showRightMenu: false });
    useCustomerMapPinStore.setState({ allowCreatePin: false, newPinCoords: {} });
  };

  return (
    <ReactModal
      // TODO: Set this
      ariaHideApp={false}
      isOpen={showPinModal}
      contentLabel="Minimal Modal Example"
      style={{
        overlay: {
          zIndex: 99999999,
        },
        content: {
          color: "lightsteelblue",
          backgroundColor: "#2d2e31",
        },
      }}>
      <div className="modal-body">
        <div className="modal-header">
          <h4 className="text-center">Create Pin</h4>
          <button onClick={closeModalAndResetPin}>
            Close <i className="fa-solid fa-times"></i>
          </button>
        </div>
        <div className="modal-content">
          <MapPinForm />
        </div>
      </div>
    </ReactModal>
  );
}
