import create from "zustand";
import { devtools } from "zustand/middleware";

import client from "../lib/client";
import {
  crewsUrl,
  crewUrl,
  equipmentCrewUrl,
  crewUpdateEquipmentUrl,
  updateCrewRoutesUrl,
  deleteCrewRoutesUrl,
  coverageMapUrl,
} from "../lib/endpoints";
import useEquipmentStore from "./equipmentStore";

let coverageTimestamp = null;

// let instead of const because we want to be able to use redux devtools
let useCrewStore = (set) => ({
  crew: null,
  crews: [],
  crewId: null,
  activeCrewToEdit: {},
  coverageMapData: [],
  loading: false,
  hasErrors: false,
  // TODO: Remove this
  stopFetching: false,
  haveFetchedCoverage: false,
  ranLast: null,
  // toRoute: null,
  // fromRoute: null,
  fetchCrew: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(equipmentCrewUrl);
      set((state) => ({ crew: response.data.crew, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchCrews: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(crewsUrl);
      set((state) => ({ crews: response.data.crews, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  createCrew: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(crewsUrl, { crew: { name: values.name } });

      if (response.data.success) {
        set((state) => ({ crews: response.data.crews, loading: false }));
        return true;
      } else {
        set((state) => ({ loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  // This split between update actions exists because there was only one way to update a crew at the time,
  // and that was to update the equipment associated with it. We can now update the name as well
  updateCrew: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(crewUrl(values.id), { id: values.id, name: values.name });
      if (response.data.success) {
        set((state) => ({ crews: response.data.crews, loading: false, activeCrewToEdit: {} }));
        return true;
      } else {
        set((state) => ({ loading: false }));
        return false;
      }
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },

  deleteCrew: async (crewId, setSelectedCrew = null) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.delete(crewUrl(crewId));
      set((state) => ({ loading: false, crews: response.data.crews }));
      if (setSelectedCrew != null) {
        setSelectedCrew(null);
      }
      alert("Successfully deleted crew.");
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      alert("There was as problem trying to delete that crew.");
    }
  },
  updateCrewEquipment: async (crewId, values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(crewUpdateEquipmentUrl(crewId), { equipment_ids: values.equipment_ids });
      useEquipmentStore.setState({ customerEquipment: response.data.customer_equipment });
      set((state) => ({ loading: false }));
      return true;
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
      return false;
    }
  },
  updateCrewRoutes: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(updateCrewRoutesUrl, { crew: { to_route: values.toRoute, from_route: values.fromRoute } });
      set((state) => ({ loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  deleteCrewRoutes: async (values) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.patch(deleteCrewRoutesUrl);
      set((state) => ({ loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
  fetchCoverageMap: async (timeSince) => {
    try {
      set((state) => ({ stopFetching: true }));
      const response = await client.get(`${coverageMapUrl}?since=${timeSince}`);
      if (response.data.encoded_polylines && response.data.encoded_polylines.length > 0 && response.data.encoded_polylines[0] !== "") {
        set((state) => ({ coverageMapData: response.data.encoded_polylines, haveFetchedCoverage: true }));
      }
    } catch (err) {
      set(() => ({ hasErrors: true }));
    }
  },
});

// This is necessary because the crew is not set at the time of the initial run.
// We need to have a shorter interval until we actually get the first batch of data.
export const processInitialCoverage = async () => {
  if (useCrewStore.getState().crew != null && !useCrewStore.getState().haveFetchedCoverage) {
    useCrewStore.getState().fetchCoverageMap(null);
    useCrewStore.getState().ranLast = new Date().toISOString();
  }

  if (!useCrewStore.getState().haveFetchedCoverage) {
    setTimeout(processInitialCoverage, 30000);
  } else {
    processCrewCoverage(false);
  }
};

export const processCrewCoverage = async () => {
  if (useCrewStore.getState().crew != null)
    useCrewStore.getState().haveFetchedCoverage
      ? useCrewStore.getState().fetchCoverageMap(useCrewStore.getState().ranLast)
      : useCrewStore.getState().fetchCoverageMap(null);
  useCrewStore.getState().ranLast = new Date().toISOString();
  setTimeout(processCrewCoverage, 30000);
};

useCrewStore = devtools(useCrewStore, { name: "CrewStore" });

export default useCrewStore = create(useCrewStore);
