import { useEffect, useState, useRef } from "react";
import { find } from "lodash";

import { createConsumer } from "@rails/actioncable";
import useUIStore from "../stores/uiStore";
import useEquipmentStore from "../stores/equipmentStore";
import useAppStore from "../stores/appStore";
import { setReloadTimestamp, setShowFields } from "../lib/storage";

// const queryString = window.location.search;
// const deviceId = new URLSearchParams(queryString).get("device");

export default function CrewCable() {
  const deviceId = useAppStore((state) => state.deviceId);
  const updateMyEquipmentLocation = useEquipmentStore((state) => state.updateMyEquipmentLocation);
  const cable = useRef();
  // const deviceId = useAppStore((state) => state.deviceId);

  useEffect(() => {
    if (deviceId) {
      if (!cable.current) {
        cable.current = createConsumer(process.env.REACT_APP_CABLE_URL);
      }

      const subscription = cable.current.subscriptions.create(
        {
          channel: "CrewChannel",
          connection_id: deviceId,
        },
        {
          connected: () => console.log("connection established"),
          disconnected: (a, b, c) => console.log("a: ", a, " b: ", b, " c: ", c),
          received: (data) => processCableResponse(data),
        }
      );

      return function cleanup() {
        cable.current = null;
        subscription.unsubscribe();
      };
    }
  }, [deviceId]);

  const processCableResponse = (data) => {
    // We get a reload: true when the crew's field assignements have changed.
    // Reload the app when getting that response
    // console.log(data);
    if (data.reload) {
      if (data.device_id !== deviceId) {
        setReloadTimestamp(new Date());
        // window.location.reload(false);
        setShowFields(true);
        useUIStore.setState({ showFieldReloadModal: true });
      }
    } else {
      useEquipmentStore.setState({
        crewEquipment: data.crew_equipment,
        crewEquipmentIds: data.crew_equipment_ids,
        animateMarkers: true,
      });

      // Update my equipment's location in the equipmentStore in order to have the click on the
      // My Equipment card accurately take you to your equipment's last location.
      const x = find(data.crew_equipment, { device_name: deviceId });
      if (!!x) {
        updateMyEquipmentLocation(x.last_location);
      }
    }
  };
}
